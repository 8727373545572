import React from 'react';
import ProjectOverviewChartClub from './ProjectOverviewChartClub';

const ProjectOverviewTabClub = () => {
    return (
        <>
            <div className="card overflow-hidden">                
                <ProjectOverviewChartClub/>
            </div>   
        </>
    );
};

export default ProjectOverviewTabClub;