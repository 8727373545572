import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

const MsgBox = ({ avatar1, avatar2, openMsg, offMsg, senderId, receiverId }) => {
   const [toggle, setToggle] = useState(false);
   const [messages, setMessages] = useState([]);
   const [firstName, setFirstName] = useState([]);
   const [lastName, setLastName] = useState([]);

   useEffect(() => {
      if (senderId && receiverId) {
         fetchMessages(senderId, receiverId);
      }
   }, [senderId, receiverId]);

   const fetchMessages = (senderId, receiverId) => {
      const apiUrl = `https://api.engageathlete.com/api/messages/${senderId}/${receiverId}`;
      const token = localStorage.getItem("token");

      if (token) {
         axios
            .get(apiUrl, {
               headers: {
                  authorization: ` ${token}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
               },
            })
            .then((response) => {
               setMessages(response.data);
               if (response.data.length > 0) {
                  setFirstName(response.data[0].user.firstName);
                  setLastName(response.data[0].user.lastName);
               }
            })
            .catch((error) => {
               console.error("Error fetching messages:", error);
            });
      } else {
         console.error("Token not found in local storage");
      }
   };

   const handleDeleteMessage = (messageId) => {
      const apiUrl = `https://api.engageathlete.com/api/messages/${messageId}`;
      const token = localStorage.getItem("token");

      if (token) {
         axios
            .delete(apiUrl, {
               headers: {
                  authorization: ` ${token}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
               },
            })
            .then((response) => {
               // Assuming success means message deleted, update UI accordingly
               // For example, refetch messages or update state to remove the deleted message
               fetchMessages(senderId, receiverId);
            })
            .catch((error) => {
               console.error("Error deleting message:", error);
            });
      } else {
         console.error("Token not found in local storage");
      }
   };
   const formatTime = (createdAt) => {
      const date = new Date(createdAt);
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // 0 should be 12 in 12-hour format
      const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
      return formattedTime;
   };

   return (
      <div className={`card chat dlab-chat-history-box ${openMsg ? "" : "d-none"}`}>
         <div className="card-header chat-list-header text-center">
            <Link to={"#"} className="dlab-chat-history-back" onClick={() => offMsg()}>
               <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                     <polygon points="0 0 24 0 24 24 0 24" />
                     <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x="14" y="7" width="2" height="10" rx="1" />
                     <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
                  </g>
               </svg>
            </Link>
            <div>
               <h6 className="mb-1">Chat </h6>
            </div>
            <div className="dropdown">

            </div>
         </div>
         <div className="card-body msg_card_body dz-scroll" id="DZ_W_Contacts_Body3">
            {messages.map((message) => (
               <div
                  key={message.id}
                  className={`d-flex justify-content-${message.senderId === senderId ? "end" : "start"} mb-4`}
               >
                  <div className={`msg_cotainer${message.senderId === senderId ? "_send" : ""}`}>
                     {message.content}{" "}
                     <span className="msg_time">{formatTime(message.createdAt)}</span>
                   
                  </div>
                  <div>
                     <span
                        className="msg_time"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteMessage(message.id)}
                     >
                        <i className="fa fa-trash text-danger ml-2"></i>
                     </span>
                  </div>
               </div>
            ))}
         </div>

      </div>
   );
};

export default MsgBox;
