import React, { useState, forwardRef, useImperativeHandle, useEffect  } from "react";

import { Offcanvas } from "react-bootstrap";

import Profileimg from "../../images/avatar/4.jpg";
import parse from 'html-react-parser';
import axios from "axios";
const stripHtmlTags = (html) => {
  const parsedHtml = parse(html);
  const styledHtml = React.Children.map(parsedHtml, (child) => {

    if (React.isValidElement(child)) {

      const style = child.props.style;

      if (style) {
        const styleString = Object.entries(style)
          .map(([key, value]) => `${key}:${value}`)
          .join(';');
        return React.cloneElement(child, { style: styleString });
      }
    }
    return child;
  });

  return styledHtml;
};
const ViewSportOffcanvas = forwardRef((props, ref) => {
  const [sportDetails, setSportDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addEmploye, setAddEmploye] = useState(false);
  useImperativeHandle(ref, () => ({
    showEmployeModal(sportId) {
      setAddEmploye(true);
      fetchSportDetails(sportId); // Fetch sport details when the modal is shown
    },
  }));
  useEffect(() => {
    // Fetch sport details when the component mounts
    fetchSportDetails();
  }, []);

  const fetchSportDetails = (sportId) => {
    console.log("Sport ID:", sportId); // Log the sport ID
   
    setLoading(true);
    const apiUrl = `https://api.engageathlete.com/api/v1/getsport/${sportId}`;
    const token = localStorage.getItem('token');

    if (token) {
      axios.get(apiUrl, {
        headers: {
          'authorization': ` ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          setSportDetails(response.data.data);
        })
        .catch(error => {
          console.error('Error fetching sport details:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('Token not found in local storage');
      setLoading(false);
    }
  };

  return (
    <>
      <Offcanvas
        show={addEmploye}
        onHide={() => setAddEmploye(false)}
        className="offcanvas-end customeoff"
        placement="end"
      >
        <div className="offcanvas-header">
          <h5 className="modal-title" id="#gridSystemModal">
            {props.Title}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAddEmploye(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
            <div className="p-2">
              <img src={Profileimg} alt={sportDetails?.sportData?.sportName}/>
            </div>
            {!loading && sportDetails && (
              <form>
                <div className="row d-flex justify-content-around">
                  <div className="col-xl-6 mb-3 view-detail-table">
                    <p>Sport Name: {sportDetails?.sportData?.sportName}</p>
                   </div>
                  <div className="col-xl-5 mb-3 view-detail-table">
                    <p>Description: {sportDetails?.sportData?.sportDescription}</p>
                  </div>
                </div>
              </form>
            )} 
          </div>
        </div>
      </Offcanvas>
    </>
  );
});

export default ViewSportOffcanvas;
