import React from 'react'
import Athlete from '../Cms/AppBanner/AthleteBanner'
import Coach from '../Cms/AppBanner/CoachBanner'
import Club from '../Cms/AppBanner/ClubBanner'

const MobileBanner = () => {
    return (
        <>
            <Athlete />
            <Coach/>
            <Club/>


        </>
    )
}

export default MobileBanner
