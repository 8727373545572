import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Offcanvas } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EditPromocode = forwardRef((props, ref) => {
  const [promoCodeDetails, setPromoCodeDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    showEmployeModal(promoId) {
      if (promoId) {
        fetchPromoCodeDetails(promoId);
      } else {
        console.error('Invalid promoId:', promoId);
      }
    },
  }));

  const fetchPromoCodeDetails = (promoId) => {
    setLoading(true);
    const apiUrl = `https://api.engageathlete.com/api/v1/getpromocode/${promoId}`;
    const token = localStorage.getItem('token');

    if (token) {
      axios.get(apiUrl, {
        headers: {
          'authorization': `${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          setPromoCodeDetails(response.data.data);
        })
        .catch(error => {
          console.error('Error fetching promo code details:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('Token not found in local storage');
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    promocodeName: Yup.string().required('Promo code value is required'),
    roleId: Yup.string().required('Profile is required'),
    discount: Yup.number().required('Discount is required').positive('Discount must be positive'),
    accessLimit: Yup.string().required('Subscription Limit is required'),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date()
      .required('End Date is required')
      .min(Yup.ref('startDate'), 'End Date cannot be before Start Date'),
    isEnable: Yup.string().required('Status is required'),
    promocodeDescription: Yup.string().required('Description is required'),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    updatePromoCodeDetails(values);
    setSubmitting(false);
  };

  const updatePromoCodeDetails = (newData) => {
    setLoading(true);
    const apiUrl = `https://api.engageathlete.com/api/v1/updatepromocode`;
    const token = localStorage.getItem('token');

    if (token) {
      axios.post(apiUrl, newData, {
        headers: {
          'authorization': `${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          console.log('Promo code details updated successfully:', response.data);
          props.onPromoCodeUpdated();
          toast.success('Promo code details updated successfully');
          setPromoCodeDetails(null);
        })
        .catch(error => {
          console.error('Error updating promo code details:', error);
          toast.error('Error updating promo code details');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('Token not found in local storage');
      setLoading(false);
    }
  };

  return (
    <>
      <Offcanvas
        show={promoCodeDetails !== null}
        onHide={() => setPromoCodeDetails(null)}
        className="offcanvas-end customeoff"
        placement="end"
      >
        <div className="offcanvas-header">
          <h5 className="modal-title" id="#gridSystemModal">
            {props.Title}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setPromoCodeDetails(null)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Formik
                initialValues={{
                  id: promoCodeDetails?.id || '',
                  promocodeName: promoCodeDetails?.promocodeName || '',
                  roleId: promoCodeDetails?.role?.id || '',
                  discount: promoCodeDetails?.discount || '',
                  accessLimit: promoCodeDetails?.accessLimit || '',
                  startDate: promoCodeDetails?.startDate ? new Date(promoCodeDetails.startDate) : null,
                  endDate: promoCodeDetails?.endDate ? new Date(promoCodeDetails.endDate) : null,
                  isEnable: promoCodeDetails?.isEnable ? "Active" : "InActive",
                  promocodeDescription: promoCodeDetails?.promocodeDescription || '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form>
                    <div className="row d-flex justify-content-around">
                      <div className="col-xl-6 mb-3">
                        <label htmlFor="promocodeName">Promo code value:</label>
                        <Field type="text" className="form-control" id="promocodeName" name="promocodeName" disabled={values.isEnable === "Active"} />
                        <ErrorMessage name="promocodeName" component="div" className="error" />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label htmlFor="roleId">Profile:</label>
                        <Field as="select" className="default-select form-control" id="roleId" name="roleId" disabled={values.isEnable === "Active"}>
                          <option value="" disabled>Select Profile</option>
                          <option value="2">Athlete</option>
                          <option value="4">Club and Academy</option>
                        </Field>
                        <ErrorMessage name="roleId" component="div" className="error" />
                      </div>

                      <div className="col-xl-6 mb-3">
                        <label htmlFor="discount">Discount:</label>
                        <Field type="text" className="form-control" id="discount" name="discount" disabled={values.isEnable === "Active"} />
                        <ErrorMessage name="discount" component="div" className="error" />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label htmlFor="accessLimit">Subscription Limit:</label>
                        <Field type="text" className="form-control" id="accessLimit" name="accessLimit" />
                        <ErrorMessage name="accessLimit" component="div" className="error" />
                      </div>

                      <div className="col-xl-6 mb-3">
                        <label htmlFor="startDate">Start Date:</label>
                        <DatePicker
                          selected={values.startDate}
                          onChange={date => setFieldValue('startDate', date)}
                          dateFormat="MMMM d, yyyy"
                          className="form-control"
                          name="startDate"
                          disabled={values.isEnable === "Active"}
                        />
                        <ErrorMessage name="startDate" component="div" className="error" />
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label htmlFor="endDate">End Date:</label>
                        <DatePicker
                          selected={values.endDate}
                          onChange={date => setFieldValue('endDate', date)}
                          dateFormat="MMMM d, yyyy"
                          minDate={values.startDate}
                          className="form-control"
                          name="endDate"
                        />
                        <ErrorMessage name="endDate" component="div" className="error" />
                      </div>

                      <div className="col-xl-6 mb-3">
                        <label htmlFor="isEnable">Status:</label>
                        <Field as="select" className="form-control" id="isEnable" name="isEnable" >
                          <option value="" disabled>Select Status</option>
                          <option value="true">Active</option>
                          <option value="false">InActive</option>
                        </Field>
                        <ErrorMessage name="isEnable" component="div" className="error" />
                      </div>
                      <div className="col-xl-12 mb-3">
                        <label htmlFor="promocodeDescription">Description:</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={values.promocodeDescription}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFieldValue('promocodeDescription', data);
                          }}
                        />
                        <ErrorMessage name="promocodeDescription" component="div" className="error" />
                      </div>

                      <div className="row">
                        <div className="col-xl-6 mb-3">
                          <button type="submit" className="btn btn-primary me-1" disabled={isSubmitting}>Submit</button>
                          <button type="button" className="btn btn-danger light ms-1" onClick={() => setPromoCodeDetails(null)}>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </Offcanvas>
    </>
  );
});

export default EditPromocode;
