import React, { useState } from 'react'
import { Link } from 'react-router-dom'

/// Images
import avatar1 from '../../../images/avatar/1.jpg'
import avatar2 from '../../../images/avatar/2.jpg'
import avatar3 from '../../../images/avatar/3.jpg'
import avatar4 from '../../../images/avatar/4.jpg'
import avatar5 from '../../../images/avatar/5.jpg'
import MsgBox from './MsgBox'

const Chat = ({ PerfectScrollbar, toggleChatBox, toggleTab }) => {
  const [openMsg, setOpenMsg] = useState(false)
  return (
    <div
        className={`tab-pane fade  ${toggleTab === 'chat' ? 'active show' : ''}`}
        id='chat'
        role='tabpanel'
    >
      <div
        className={`card mb-sm-3 mb-md-0 contacts_cazrd dlab-chat-user-box ${
          openMsg ? 'd-none' : ''
        }`}
      >
        
        <div
          className={`card-body contacts_body p-0 dz-scroll  ${
            toggleChatBox ? '' : ''
          }`}
          id='DZ_W_Contacts_Body'
        >
          <ul className='contacts'>
            
            <li
              className='active dlab-chat-user'
              onClick={() => setOpenMsg(true)}
            >
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar1}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon'></span>
                </div>
                <div className='user_info'>
                  <span>Archie Parker</span>
                  <p>Kalid is online</p>
                </div>
              </div>
            </li>
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar2}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon offline'></span>
                </div>
                <div className='user_info'>
                  <span>Alfie Mason</span>
                  <p>Taherah left 7 mins ago</p>
                </div>
              </div>
            </li>
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar3}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon'></span>
                </div>
                <div className='user_info'>
                  <span>AharlieKane</span>
                  <p>Sami is online</p>
                </div>
              </div>
            </li>
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar4}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon offline'></span>
                </div>
                <div className='user_info'>
                  <span>Athan Jacoby</span>
                  <p>Nargis left 30 mins ago</p>
                </div>
              </div>
            </li>
            
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar5}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon offline'></span>
                </div>
                <div className='user_info'>
                  <span>Bashid Samim</span>
                  <p>Rashid left 50 mins ago</p>
                </div>
              </div>
            </li>
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar1}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon'></span>
                </div>
                <div className='user_info'>
                  <span>Breddie Ronan</span>
                  <p>Kalid is online</p>
                </div>
              </div>
            </li>
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar2}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon offline'></span>
                </div>
                <div className='user_info'>
                  <span>Ceorge Carson</span>
                  <p>Taherah left 7 mins ago</p>
                </div>
              </div>
            </li>
            
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar3}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon'></span>
                </div>
                <div className='user_info'>
                  <span>Darry Parker</span>
                  <p>Sami is online</p>
                </div>
              </div>
            </li>
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar4}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon offline'></span>
                </div>
                <div className='user_info'>
                  <span>Denry Hunter</span>
                  <p>Nargis left 30 mins ago</p>
                </div>
              </div>
            </li>
            
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar5}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon offline'></span>
                </div>
                <div className='user_info'>
                  <span>Jack Ronan</span>
                  <p>Rashid left 50 mins ago</p>
                </div>
              </div>
            </li>
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar1}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon'></span>
                </div>
                <div className='user_info'>
                  <span>Jacob Tucker</span>
                  <p>Kalid is online</p>
                </div>
              </div>
            </li>
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar2}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon offline'></span>
                </div>
                <div className='user_info'>
                  <span>James Logan</span>
                  <p>Taherah left 7 mins ago</p>
                </div>
              </div>
            </li>
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar3}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon'></span>
                </div>
                <div className='user_info'>
                  <span>Joshua Weston</span>
                  <p>Sami is online</p>
                </div>
              </div>
            </li>
            
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar4}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon offline'></span>
                </div>
                <div className='user_info'>
                  <span>Oliver Acker</span>
                  <p>Nargis left 30 mins ago</p>
                </div>
              </div>
            </li>
            <li className='dlab-chat-user' onClick={() => setOpenMsg(true)}>
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar5}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon offline'></span>
                </div>
                <div className='user_info'>
                  <span>Oscar Weston</span>
                  <p>Rashid left 50 mins ago</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <MsgBox
        avatar1={avatar1}
        avatar2={avatar2}
        openMsg={openMsg}
        // PerfectScrollbar={PerfectScrollbar}
        offMsg={() => setOpenMsg(false)}
      />
    </div>
  )
}

export default Chat
