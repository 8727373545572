import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
} from '../actions/AuthActions';

const saveAuthStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('authData', serializedState);
    } catch (e) {
        console.error('Could not save state', e);
    }
};

const initialState = {
    isAuthenticated: false,
    token: null,
    userDetails: null,
};

export function AuthReducer(state = initialState, action) {
    switch (action.type) {
        case SIGNUP_CONFIRMED_ACTION:
        case LOGIN_CONFIRMED_ACTION:
            const authData = {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                errorMessage: '',
                // successMessage: action.type === SIGNUP_CONFIRMED_ACTION ? 'Signup Successfully Completed' : 'Login Successfully Completed',
                showLoading: false,
            };
            saveAuthStateToLocalStorage(authData);
            return authData;
        case LOGOUT_ACTION:
            localStorage.removeItem('authData');
            return {
                ...initialState,
            };
        case SIGNUP_FAILED_ACTION:
        case LOGIN_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: action.payload,
            };
        default:
            return state;
    }
}
