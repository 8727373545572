import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const SportOffcanvas = forwardRef((props, ref) => {
  const [AddEmploye, setAddEmploye] = useState(false);
  const [sportName, setSportName] = useState('');
  const [sportDescription, setSportDescription] = useState('');
  const [logoFile, setLogoFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useImperativeHandle(ref, () => ({
    showEmployeModal() {
      setAddEmploye(true);
    }
  }));

  const validationSchema = Yup.object().shape({
    sportName: Yup.string()
      .required('Sport Name is required')
      .max(50, 'Sport Name must be within 50 characters'),
    sportDescription: Yup.string()
      .required('Sport Description is required')
  });

  const handleLogoChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];
      const maxFileSize = 200 * 1024; // 200 KB in bytes
  
      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {
        console.log("Invalid file type. Please upload a JPEG, JPG, PNG, or SVG file.");
        toast.error("Invalid file type. Please upload a JPEG, JPG, PNG, or SVG file.");
        e.target.value = null;
        setPreviewImage(null);
        return;
      }
  
      // Check if the file size exceeds the limit
      if (file.size >= maxFileSize) {
        console.log("File size exceeds the limit of 200 KB.");
        toast.error("File size exceeds the limit of 200 KB.");
        e.target.value = null; // Reset the file input field
        setPreviewImage(null); // Clear the preview image
        return;
      }
  
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        if (width >= 290 && width <= 300 && height >= 290 && height <= 300) {
          // If dimensions are within the allowed range
          console.log("Selected file:", file);
          setLogoFile(file);
  
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(file);
        } else {
          // If dimensions are not within the allowed range
          console.log("Image dimensions should be between 290 and 300 pixels in both height and width.");
          toast.error("Image dimensions should be between 290 and 300 pixels in both height and width.");
          e.target.value = null;
          setPreviewImage(null);
        }
      };
      img.src = URL.createObjectURL(file);
    } else {
      console.log("No file selected.");
      setLogoFile(null);
      setPreviewImage(null);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('sportName', values.sportName);
      formData.append('sportDescription', values.sportDescription);
      if (logoFile) {
        formData.append('sportLogo', logoFile);
      }

      const response = await axios.post('https://api.engageathlete.com/api/v1/addsport', formData, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });

      if (response.status === 200) {
        const addedSportData = response.data.data;
        props.onSportAdded(addedSportData);
        setAddEmploye(false);
        props.onsportUpdated();
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error('Error adding sport:', error);
      setAddEmploye(false);
      toast.error(error?.response?.data?.message);
    }
    setSubmitting(false);
  };

  return (
    <Offcanvas show={AddEmploye} onHide={() => setAddEmploye(false)} className="offcanvas-end customeoff" placement='end'>
      <div className="offcanvas-header profile-head-h">
        <h5 className="modal-title text-white" id="#gridSystemModal">{props.Title}</h5>
        <button type="button" className="btn-close" onClick={() => setAddEmploye(false)}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <Formik
            initialValues={{ sportName: '', sportDescription: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="col-xl-6 mb-3">
                  <label>Profile Picture</label>
                  {previewImage && (
                    <div>
                      <img src={previewImage} className='mb-3' alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                    </div>
                  )}
                  <div className="dz-default dlab-message upload-img mb-3">
                    <input
                      type="file"
                      id="profileImg"
                      name="profileImg"
                      onChange={handleLogoChange}
                      className="form-control"
                    />
                    <p className="text-danger">Height & Width should be between 290 - 300</p>
                    <p className="text-danger">only GIF, JPG, JPEG, PNG, or SVG images up to 200 KB.</p>
                  </div>
                </div>
                <div className="col-xl-6 mb-3">
                  <label htmlFor="exampleFormControlInput2" className="form-label">Sport Name <span className="text-danger">*</span></label>
                  <Field type="text" className="form-control" id="exampleFormControlInput2" name="sportName" />
                  <ErrorMessage name="sportName" component="div" className="text-danger" />
                </div>
                <div className="col-xl-12 mb-3">
                  <label className="form-label">Sport Description<span className="text-danger">*</span></label>
                  <Field as="textarea" rows="2" className="form-control" name="sportDescription" />
                  <ErrorMessage name="sportDescription" component="div" className="text-danger" />
                </div>
                <div>
                  <button type="submit" className="btn btn-primary me-1" disabled={isSubmitting}>Submit</button>
                  <Link to="#" onClick={() => setAddEmploye(false)} className="btn btn-danger light ms-1">Cancel</Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Offcanvas>
  );
});

export default SportOffcanvas;
