import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import MainPagetitle from "../../layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import ViewClubTransaction from "../../constant/ClubTransaction";
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import DatePicker from 'react-datepicker';

const ClubReport = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [statusPriority, setStatusPriority] = useState([]);
  const [data, setData] = useState([]);
  const [sports, setSports] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedStateIds, setSelectedStateIds] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [filters, setFilters] = useState({
    academieName: '',
    state: '',
    city: '',
    isSubscription: 'Active',
    isPublish: '',
    startDate: '',
    endDate: '',
    sportsId: '',
    email: ''
  });
  const [activePag, setActivePag] = useState(() => {
    const storedPage = sessionStorage.getItem('activePag');
    return storedPage ? parseInt(storedPage) : 0;
  });
  const sort = 10;

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
    fetchTableData({ ...filters, [name]: value });
  };

  const handleSelectChange = (selected, { name }) => {
    let value = '';

    if (selected) {
      if (Array.isArray(selected)) {
        value = selected.map(option => option.value).join(',');
      } else {
        value = selected.value;
      }
    }

    console.log(`Selected ${name}:`, value);

    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));

    fetchTableData({ ...filters, [name]: value });
  };

  useEffect(() => {
    fetchStates();
    fetchSports();
    fetchTableData();
  }, []);
  const handleDateChange = (date, name) => {
    setFilters(prev => ({ ...prev, [name]: date }));
    fetchTableData({ ...filters, [name]: date });
  };
  useEffect(() => {
    if (selectedStateIds.length > 0) {
      fetchCitiesByState(selectedStateIds.map(state => state.value).join(","));
    } else {
      setCities([]);
    }
  }, [selectedStateIds]);

  const fetchTableData = (updatedFilters = filters) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/academiepurchasereport`;
    const token = localStorage.getItem('token');
    const formattedFilters = {
      ...updatedFilters,
      startDate: formatForAPI(updatedFilters.startDate),
      endDate: formatForAPI(updatedFilters.endDate)
    };
  
    if (token) {
      axios.get(apiUrl, {
        headers: {
          'authorization': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        params: formattedFilters
      })
        .then(response => {
          setTableData(response.data.transactionHistories);
          setStatusPriority(response.data.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    } else {
      console.error('Token not found in local storage');
      setLoading(false);
    }
  };
  const generateCSVData = () => {
    return tableData.map(user => ({
      academieName: user.user.academies[0]?.academieName,
      sports: user.user.academies[0]?.sportsData?.map(sport => sport?.sportName).join(', ')|| '',
      city: user.user.academies?.[0]?.cityData?.map(city => city?.name)?.join(', ') || '',
      state: user.user.academies?.[0]?.stateData?.map(state => state?.name)?.join(', ') || '',      
      email: user.user.email,
      subscriptionName: user.subscriptionName || '',
      startDate: formatDate(user.startDate) || '',
      endDate: formatDate(user.endDate) || '',
      renewDate: formatDate(new Date(new Date(user.endDate).setDate(new Date(user.endDate).getDate() + 1))) || '',
      subscriptionMonth: `${user.subscriptionMonth} Months` || '',
      totalAmount: user.totalAmount || '',
      discount: user.discount || '',
      processingTax: user.processingTax || '',
      convenienceTax: user.convenienceTax || '',
      serviceTax: user.serviceTax || '',
    }));
  };
  const formatForAPI = (date) => {
    if (!date) return '';
    return new Date(date).toISOString().split('T')[0]; // yyyy-mm-dd
  };
  const fetchStates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallstates`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': token,
        }
      });
      setStates(response.data.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  const fetchCitiesByState = async (stateIds) => {
    try {
      const token = localStorage.getItem('token');
      setCities([]);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallcities`, {
        params: { stateId: stateIds },
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': token,
        }
      });
      setCities(response.data.citiesData);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fetchSports = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallsports`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': token,
        }
      });
      setSports(response.data.data);
    } catch (error) {
      console.error('Error fetching sports:', error);
    }
  };

  const [test, setTest] = useState(0);

  useEffect(() => {
    setData(document.querySelectorAll("#employee-tbl_wrapper tbody tr"));
  }, [test]);

  const onClickPage = (page) => {
    setActivePag(page);
    sessionStorage.setItem('activePag', page);
  };

  const pagination = useMemo(() => Array.from({ length: Math.ceil(data.length / sort) }, (_, index) => index + 1), [data]);
  const handleReset = () => {
    setFilters({
      academieName: '',
      state: '',
      city: '',
      isSubscription: 'Active',
      isPublish: '',
      startDate: '',
      endDate: '',
      sportsId: '',
      email: ''
    });
    setSelectedStateIds([]);
    setSelectedCities([]);
    fetchTableData({
      academieName: '',
      state: '',
      city: '',
      isSubscription: 'Active',
      isPublish: '',
      startDate: '',
      endDate: '',
      sportsId: '',
      email: ''
    });
  };
  const viewclub = useRef();
  const splitIntoLines = (text, wordsPerLine) => {
    const words = text.split(' ');
    let result = '';
    for (let i = 0; i < words.length; i += wordsPerLine) {
      result += words.slice(i, i + wordsPerLine).join(' ') + '<br>';
    }
    return result;
  };
  const formatDate = (date) => {
    if (!date) return '';
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(date).toLocaleDateString('en-US', options);
  };
  return (
    <>
      <div className="col-xl-12 text-end mt-3 d-flex">
        <div className="col-xl-10 text-end mb-3">
          <MainPagetitle
            mainTitle="Club Report"
            pageTitle="Club Report"
            parentTitle="Home"
          />
        </div>
        <div className="col-xl-2 text-end mb-3">
          <CSVLink
            data={generateCSVData()}
            filename={`club_report_${new Date().toLocaleDateString()}.csv`}
            className="btn btn-primary"
            target="_blank"
          >
            Export to CSV
          </CSVLink>
        </div>
      </div>
      <div className="">
        <div className="row">
          <div className="col-xl-12">
            <div className="filter cm-content-box box-primary">
              <Collapse in={open}>
                <div className="cm-content-body form excerpt">
                  <div className="card-body">
                    <div className="row filter-row">
                      <div className="col-xl-2 col-sm-6">
                        <input
                          type="text"
                          className="form-control mb-xl-0 mb-3"
                          name="academieName"
                          value={filters.academieName}
                          onChange={handleFilterChange}
                          placeholder="Club Name"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <Select
                          options={sports.map(sport => ({ value: sport.id, label: sport.sportName }))}
                          value={filters.sportsId ? filters.sportsId.split(',').map(id => ({ value: id, label: sports.find(sport => sport.id === parseInt(id))?.sportName })) : []}
                          onChange={(selected) => handleSelectChange(selected, { name: 'sportsId' })}
                          isSearchable
                          isMulti
                          placeholder="Select Sport"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <Select
                          isSearchable
                          options={states.map(state => ({ value: state.id, label: state.name }))}
                          className="custom-react-select"
                          name="state"
                          value={filters.state ? filters.state.split(',').map(id => ({ value: id, label: states.find(state => state.id === parseInt(id))?.name })) : []}
                          onChange={handleSelectChange}
                          isMulti
                          placeholder="Select State"
                        />


                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <Select
                          isSearchable
                          options={cities.map(city => ({ value: city.id, label: city.name }))}
                          className="custom-react-select"
                          name="city"
                          value={filters.city ? filters.city.split(',').map(id => ({ value: id, label: cities.find(city => city.id === parseInt(id))?.name })) : []}
                          onChange={handleSelectChange}
                          isMulti
                          placeholder="Select City"
                        />
                      </div>

                      <div className="col-xl-2 col-sm-6">
                        <DatePicker
                          selected={filters.startDate}
                          onChange={(date) => handleDateChange(date, 'startDate')}
                          className="form-control mb-xl-0 mb-3"
                          placeholderText="Start Date"
                          dateFormat="MM/dd/yyyy"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <DatePicker
                          selected={filters.endDate}
                          onChange={(date) => handleDateChange(date, 'endDate')}
                          className="form-control mb-xl-0 mb-3"
                          placeholderText="End Date"
                          dateFormat="MM/dd/yyyy"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <button
                          className="btn btn-secondary"
                          title="Click here to Reset"
                          type="button"
                          onClick={handleReset}
                        >
                          <i class="bi bi-arrow-clockwise me-1"></i>Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">

                  <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                    <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                      <thead>
                        <tr>

                          <th>Club/Academy Name</th>
                          <th>sports</th>
                          <th>Resendtial City</th>
                          <th>Resendtial State</th>
                          <th>Email</th>

                          <th>Subscription Plan</th>
                          <th>Subscription Start Date</th>
                          <th>Subscription End Date</th>
                          <th>Subscription Renewal Date</th>
                          <th>Subscription Duration </th>
                          <th>Subscription Amount </th>
                          <th>Discount Amount </th>
                          <th>Processing Fee</th>
                          <th>Convenience Fee</th>
                          <th>Service Fee</th>

                          <th>Total Subscription Amount</th>

                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.user.academies[0]?.academieName}</td>
                            <td>
                              <div
                                style={{ width: '100%' }}
                                dangerouslySetInnerHTML={{
                                  __html: item.user.academies[0].sportsData
                                    .map((sportItem, sportIndex) =>
                                      sportIndex > 0 ? `, ${sportItem.sportName}` : sportItem.sportName
                                    )
                                    .join(' ')
                                    .split('.')
                                    .map(sentence => splitIntoLines(sentence, 5))
                                    .join('')
                                }}
                              />
                            </td>
                            <td>
                              <div
                                style={{ width: '100%' }}
                                dangerouslySetInnerHTML={{
                                  __html: item.user.academies[0].cityData
                                    .map((cityItem, cityIndex) =>
                                      cityIndex > 0 ? `, ${cityItem.name}` : cityItem.name
                                    )
                                    .join(' ')
                                    .split('.')
                                    .map(sentence => splitIntoLines(sentence, 7))
                                    .join('')
                                }}
                              />
                            </td>
                            <td>
                              <div
                                style={{ width: '100%' }}
                                dangerouslySetInnerHTML={{
                                  __html: item?.user?.academies[0]?.stateData
                                    .map((stateItem, stateIndex) =>
                                      stateIndex > 0 ? `, ${stateItem?.name}` : stateItem?.name
                                    )
                                    .join(' ')
                                    .split('.')
                                    .map(sentence => splitIntoLines(sentence, 7))
                                    .join('')
                                }}
                              />
                            </td>
                            <td> <span>{item.user.email}</span></td>

                            <td> <span>{item.subscriptionName}</span></td>
                            <td className="text-center"><span>{formatDate(item.startDate)}</span></td>
                            <td className="text-center"><span>{formatDate(item.endDate)}</span></td>

                            <td className="text-center"> <span> {new Date(new Date(item.endDate).setDate(new Date(item.endDate).getDate() + 1)).toLocaleDateString("en-US")}</span></td>
                            <td className="text-center"> <span>{item.subscriptionMonth} Months</span></td>
                            <td className="text-center"> <span>{item.totalAmount}</span></td>
                            <td className="text-center"> <span>{item.discount}</span></td>
                            <td className="text-center"> <span>{item.processingTax}</span></td>
                            <td className="text-center"> <span>{item.convenienceTax}</span></td>
                            <td className="text-center"> <span>{item.serviceTax}</span></td>
                            <td className="text-center"> <span>{item.totalAmount}</span></td>
                            {/* <td>{item.user.email}</td>
                          
                            <td>{item.subscription_startdate}</td>
                            <td>{item.subscription_enddate}</td>
                            <td>{item.subscription_nextdate}</td>
                            <td>{item.term_subscription}</td>
                            <td>{item.subscriptionAmount}</td>
                            <td>{item.discount_amount}</td>
                            <td>{item.tax_amount}</td>
                            <td>{item.totalSubscription_amount}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing  {activePag + 1} page of {data.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers" id="example2_paginate">
                        <Link
                          className={`paginate_button previous ${activePag === 0 ? 'disabled' : ''}`}
                          to=""
                          onClick={(e) => {
                            if (activePag > 0) {
                              onClickPage(activePag - 1);
                            } else {
                              e.preventDefault();
                            }
                          }}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {pagination.map((number, i) => (
                            <Link
                              key={i}
                              to=""
                              className={`paginate_button ${activePag === i ? 'current' : ''}`}
                              onClick={(e) => {
                                e.preventDefault();
                                onClickPage(i);
                              }}
                            >
                              {number}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className={`paginate_button next ${activePag + 1 >= pagination.length ? 'disabled' : ''}`}
                          to=""
                          onClick={(e) => {
                            if (activePag + 1 < pagination.length) {
                              onClickPage(activePag + 1);
                            } else {
                              e.preventDefault();
                            }
                          }}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewClubTransaction ref={viewclub} Title="Transaction Details" />

    </>
  );
};

export default ClubReport;
