import React from "react";
import PlatformBenefits from "./Home/PlatformBenefits";
import WelcomeConnect from "./Home/WelcomeConnect";
import Banner from "./Home/Banner";
import OurSports from "./Home/OurSports";
import HowItWorks from "./Home/HowItWorks";
import OurSponsors from "./Home/OurSponsors";
import OurPartners from "./Home/OurPartners";


const HomePage = () => {
  return (
    <>   
      <Banner/>
      <WelcomeConnect/>
      <PlatformBenefits/>
      <OurSports/>
      <HowItWorks/>
      <OurSponsors/>
      <OurPartners/>
    </>
  );
};

export default HomePage;
