import React from 'react'

import Addannounce from '../Cms/AddAnnoucement';
const AddAnnouncement = () => {
    return (
        <>
        <Addannounce/>
        
         
        </>
    )
}

export default AddAnnouncement
