import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const EditsportOffcanvas = forwardRef((props, ref) => {
    const [id, setId] = useState('');
    const [sportName, setSportName] = useState('');
    const [sportDescription, setSportDescription] = useState('');
    const [sportLogoId, setSportLogoId] = useState(null);
    const [profile, setProfile] = useState(null);
    const [sportDetails, setSportDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addEmploye, setAddEmploye] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    useImperativeHandle(ref, () => ({
        showEmployeModal(sportId) {
            setAddEmploye(true);
            fetchSportDetails(sportId); 
        },
    }));

    useEffect(() => {
        if (sportDetails) {
            setId(sportDetails.sportData.id);
            setSportName(sportDetails.sportData.sportName);
            setSportDescription(sportDetails.sportData.sportDescription);
            setProfile(sportDetails.sportData.sportLogo);
            setSportLogoId(sportDetails.sportData.id);
        }
    }, [sportDetails]);

    const validationSchema = Yup.object().shape({
        sportName: Yup.string()
            .required('Sport Name is required')
            .max(50, 'Sport Name must be within 50 characters'),
        sportDescription: Yup.string()
            .required('Sport Description is required')
    });

    const fetchSportDetails = (sportId) => {
        setLoading(true);
        const apiUrl = `https://api.engageathlete.com/api/v1/getsport/${sportId}`;
        const token = localStorage.getItem('token');

        if (token) {
            axios.get(apiUrl, {
                headers: {
                    'authorization': `${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    setSportDetails(response.data.data);
                    setProfile(response.data.data.sportData?.sportLogo);
                })
                .catch(error => {
                    console.error('Error fetching sport details:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.error('Token not found in local storage');
            setLoading(false);
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('id', id);
        formData.append('sportName', values.sportName);
        formData.append('sportLogo', selectedFile);
        formData.append('sportDescription', values.sportDescription);
        // formData.append('galleryId', sportLogoId);
        formData.append('isActive', "true");

        try {
            // if (selectedFile) {
            //     const fileFormData = new FormData();
            //     fileFormData.append('id', sportLogoId);
            //     fileFormData.append('fileLocation', selectedFile, selectedFile.name);
            //     fileFormData.append('description', "updated Sport Logo");
            //     fileFormData.append('isActive', "true");
            //     fileFormData.append('fileType', selectedFile?.type);
            //     fileFormData.append('isApproved', "true");
            //     await axios.post('https://api.engageathlete.com/api/v1/update-file', fileFormData, {
            //         headers: {
            //             'authorization': `${token}`,
            //             'Content-Type': 'multipart/form-data',
            //         }
            //     });
            // }
            

            const response = await axios.post('https://api.engageathlete.com/api/v1/updatesport', formData, {
                headers: {
                    'authorization': `${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            });

            toast.success("Sport details updated successfully");
            console.log('Response:', response.data);

            props.onsportUpdated();
            setAddEmploye(false);
        } catch (error) {
            console.error('Error:', error);
            toast.error("Failed to update sport details");
        } finally {
            setSubmitting(false);
        }
    };

    const handlesportLogoIdChange = (e) => {
        const newFile = e.target.files[0];
        setSelectedFile(newFile);
        if (newFile) {
            const fileUrl = URL.createObjectURL(newFile);
            setProfile(fileUrl);
        } else {
            setProfile(null);
        }

        if (sportDetails && sportDetails.sportData.gallery) {
            setSportLogoId(sportDetails.sportData.gallery.id);
        } else {
            console.error("Gallery ID is not found or undefined. sportDetails:", sportDetails);
        }
    };

    if (loading) {
        return (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                
            </div>
        );
    }

    return (
        <>
            <Offcanvas show={addEmploye} onHide={() => setAddEmploye(false)} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header profile-head-h">
                    <h5 className="modal-title text-white" id="#gridSystemModal">{props.Title}</h5>
                    <button type="button" className="btn-close" onClick={() => setAddEmploye(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <div>
                            {!loading && sportDetails && (
                                <Formik
                                    initialValues={{
                                        sportName: sportName,
                                        sportDescription: sportDescription
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                    enableReinitialize
                                >
                                    {({ isSubmitting, values }) => (
                                        <Form>
                                            <div>
                                                <label>Sport Logo</label>
                                                <div className="dz-default dlab-message upload-img mb-3">
                                                    <input type="file" accept="image/*" onChange={handlesportLogoIdChange} />
                                                    {profile && (
                                                        <img src={profile} alt="Selected File" style={{ maxWidth: '100px', marginTop: '10px' }} />
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="sportName" className="form-label">Sport Name <span className="text-danger">*</span></label>
                                                <Field type="text" className="form-control" id="sportName" placeholder="" name="sportName" />
                                                <ErrorMessage name="sportName" component="div" className="text-danger" />
                                            </div>

                                            <div className="col-xl-12 mb-3">
                                                <label className="form-label">Sport Description <span className="text-danger">*</span></label>
                                                <Field as="textarea" rows="2" className="form-control" name="sportDescription" />
                                                <ErrorMessage name="sportDescription" component="div" className="text-danger" />
                                            </div>

                                            <div>
                                                <button type="submit" className="btn btn-primary me-1" disabled={isSubmitting}>Submit</button>
                                                <Link to="#" onClick={() => setAddEmploye(false)} className="btn btn-danger light ms-1">Cancel</Link>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </div>
                    </div>
                </div>
            </Offcanvas>
        </>
    );
});

export default EditsportOffcanvas;
