import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';

const tableData = [
    { number: "1", title: "Home", url: "/add-homepage", status: "Published" },
    { number: "2", title: "About", url: "/about-page", status: "Draft" },
    { number: "3", title: "Contact", url: "/contact-us", status: "Published" },
    { number: "4", title: "Terms & Conditions", url: "/terms-conditions", status: "Published" },
    { number: "5", title: "Privacy Policy", url: "/privacy-policy", status: "Published" },
    { number: "6", title: "Parent Consent-Athlete", url: "/athlete-intake", status: "Draft" },
    { number: "7", title: "App Banners", url: "/app-banner", status: "Draft" }, 
];

const Content = () => {
    const [open2, setOpen2] = useState(true);
    const [data, setData] = useState(document.querySelectorAll("#content_wrapper tbody tr"));
    const sort = 8;
    const activePag = useRef(0);
    const [test, settest] = useState(0);

    // Active data
    const chageData = (frist, sec) => {
        for (var i = 0; i < data.length; ++i) {
            if (i >= frist && i < sec) {
                data[i].classList.remove("d-none");
            } else {
                data[i].classList.add("d-none");
            }
        }
    };

    useEffect(() => {
        setData(document.querySelectorAll("#content_wrapper tbody tr"));
    }, [test]);

    activePag.current === 0 && chageData(0, sort);
    let paggination = Array(Math.ceil(data.length / sort)).fill().map((_, i) => i + 1);

    const onClick = (i) => {
        activePag.current = i;
        chageData(activePag.current * sort, (activePag.current + 1) * sort);
        settest(i);
    };

    const [deleteItem] = useState(tableData);

    return (
        <>
            <div className="page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item "><Link to={"/content"}>CMS</Link></li>
                    <li className="breadcrumb-item active"><Link to={"#"}>Content</Link></li>
                </ol>
            </div>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="mb-3">
                            {/* <Link to={"/add-content"} className="btn btn-primary">Add Content</Link> */}
                        </div>
                        <div className="filter cm-content-box box-primary">
                            <div className={`content-title`}>
                                <div className="cpa">
                                    <i className="fas fa-file-word me-2"></i>
                                    Page List
                                </div>
                                <div className="tools">
                                    <Link to={"#"} className={`SlideToolHeader ${open2 ? 'collapse' : 'expand'}`}
                                        onClick={() => setOpen2(!open2)}
                                    >
                                        <i className="fas fa-angle-up"></i>
                                    </Link>
                                </div>
                            </div>
                            <Collapse in={open2}>
                                <div className="cm-content-body form excerpt">
                                    <div className="card-body py-3">
                                        <div className="table-responsive">
                                            <div id="content_wrapper" className="dataTables_wrapper no-footer">
                                                <table className="table table-responsive-lg table-striped table-condensed flip-content">
                                                    <thead>
                                                        <tr>
                                                            <th className='text-black'>S.No</th>
                                                            <th className='text-black'>Title</th>
                                                            <th className="text-black text-end">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {deleteItem.map((item, ind) => (
                                                            <tr key={ind}>
                                                                <td>{item.number}</td>
                                                                <td>{item.title}</td>
                                                                <td className='text-end'>
                                                                   
                                                                    <Link to={item.url} className="btn btn-warning btn-sm content-icon me-1">
                                                                        <i className="fa fa-edit"></i>
                                                                    </Link>

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                                                    <div className="dataTables_info">
                                                        Showing {activePag.current * sort + 1} to{" "}
                                                        {data.length > (activePag.current + 1) * sort
                                                            ? (activePag.current + 1) * sort
                                                            : data.length}{" "}
                                                        of {data.length} entries
                                                    </div>
                                                    <div className="dataTables_paginate paging_simple_numbers" id="example2_paginate">
                                                        <Link
                                                            className="paginate_button previous disabled"
                                                            to="/content"
                                                            onClick={() =>
                                                                activePag.current > 0 && onClick(activePag.current - 1)
                                                            }
                                                        >
                                                            <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                        </Link>
                                                        <span>
                                                            {paggination.map((number, i) => (
                                                                <Link
                                                                    key={i}
                                                                    to="/content"
                                                                    className={`paginate_button  ${activePag.current === i ? "current" : ""} `}
                                                                    onClick={() => onClick(i)}
                                                                >
                                                                    {number}
                                                                </Link>
                                                            ))}
                                                        </span>
                                                        <Link
                                                            className="paginate_button next"
                                                            to="/content"
                                                            onClick={() =>
                                                                activePag.current + 1 < paggination.length && onClick(activePag.current + 1)
                                                            }
                                                        >
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Content;
