import React, { useState } from 'react'
/// Images
import avatar1 from '../../../images/avatar/1.jpg'
import avatar2 from '../../../images/avatar/2.jpg'
import avatar3 from '../../../images/avatar/3.jpg'
import avatar4 from '../../../images/avatar/4.jpg'
import avatar5 from '../../../images/avatar/5.jpg'
import MsgBox from '../chatBox/Chat'
const Messenger = () => {
  return (
    <div>
<MsgBox/>
    </div>
  )
}

export default Messenger
