import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminProfile = () => {
    const [userProfiles, setUserProfiles] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [profile, setProfile] = useState(null);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        profileImage: '',
        email: ''
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [galleryId, setGalleryId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('Token not found in localStorage');
                    return;
                }

                const config = {
                    headers: {
                        authorization: ` ${token}`
                    }
                };

                const response = await axios.get('https://api.engageathlete.com/api/v1/getadmin', config);
                console.log('Response:', response.data);
                setProfile(response?.data?.data?.galleries?.[0]?.fileLocation || 'default_image_url_here');
                const fetchedGalleryId = response?.data?.data?.galleries?.[0]?.id;
                setGalleryId(fetchedGalleryId);
                console.log(response.data.data.galleries?.[0])
                if (Array.isArray(response.data)) {
                    setUserProfiles(response.data);
                } else if (response.data && typeof response.data === 'object') {
                    const profilesArray = Object.values(response.data);
                    setUserProfiles(profilesArray);
                } else {
                    console.error('Invalid response format');
                }


                const respons = await axios.get('https://api.engageathlete.com/api/v1/getadmin', {
                  headers: {
                    'authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  }
                });

                setDataFetched(true);
            } catch (error) {
                console.error('Error fetching user data:', error);

                setDataFetched(true);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (userProfiles.length > 0) {
            const latestProfile = userProfiles[userProfiles.length - 1];
            setFormData({
                firstName: latestProfile.firstName,
                lastName: latestProfile.lastName,
                profileImage: latestProfile.profile,
                email: latestProfile.email
            });
        }
    }, [userProfiles]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');


            const config = {
                headers: {
                    authorization: ` ${token}`
                }
            };

            const response = await axios.post('https://api.engageathlete.com/api/v1/editadmin', formData, config);

            if (response.data.status === 200 && response.data) {
                console.log("Response:", response.data);

                if (selectedFile && !galleryId) {
                    const adminResponse = await axios.get('https://api.engageathlete.com/api/v1/getadmin', {
                        headers: {
                            'Accept': 'application/json',
                            'authorization': localStorage.getItem('token'),
                        }
                    });
                    const adminId = adminResponse.data.data.id;

                    console.log("Profile Uploading")
                    const fileFormData = new FormData();
                    fileFormData.append('userId', adminId);
                    fileFormData.append('fileLocation', selectedFile, selectedFile.name);
                    fileFormData.append('description', "New Profile Image");
                    fileFormData.append('isActive', "true");
                    fileFormData.append('fileType', "admin Profile");
                    fileFormData.append('isApproved', "true");

                    await axios.post('https://api.engageathlete.com/api/v1/upload-singlefile', fileFormData, {
                        headers: {
                            'authorization': `${token}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                    console.log("File uploaded successfully!");
                    toast.success("File uploaded successfully!");
                }

                // Update existing profile picture
                if (selectedFile && galleryId) {
                    const fileFormData = new FormData();
                    fileFormData.append('id', galleryId);
                    fileFormData.append('userId', response.data);
                    fileFormData.append('fileLocation', selectedFile, selectedFile.name);
                    fileFormData.append('description', "Updated Profile Image");
                    fileFormData.append('isActive', "true");
                    fileFormData.append('fileType', "admin Profile");
                    fileFormData.append('isApproved', "true");

                    await axios.post('https://api.engageathlete.com/api/v1/update-file', fileFormData, {
                        headers: {
                            'authorization': `${token}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                    console.log("File updated successfully!");
                    toast.success("File updated successfully!");
                }

            }
            toast.success('Profile updated successfully!');
            navigate('/dashboard');
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error('Failed to update profile. Please try again.');
        }
    };

    const displayData = () => {
        return (
            
            <div className="authincation">
                <div className="container">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-md-6">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form">
                                            <h4 className="text-center mb-4">Admin Profile</h4>
                                            <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                    <label><strong>Profile Image</strong></label>
                                                    {profile && (
                                                        <img src={profile}  alt="Selected File" style={{ maxWidth: '100px', marginTop: '10px', marginLeft: '20px', marginBottom: '20px' }} />
                                                    )}

                                                    <input
                                                        type="file"
                                                        name="profileImg"
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            setSelectedFile(file);
                                                            const reader = new FileReader();
                                                           
                                                            reader.onload = () => {
                                                                setProfile(reader.result);
                                                            };
                                                            reader.readAsDataURL(file);
                                                        }}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label><strong>First Name</strong></label>
                                                    <input type="text" className="form-control" name="firstName" value={formData.firstName} onChange={handleChange} />
                                                </div>
                                                <div className="mb-3">
                                                    <label><strong>Last Name</strong></label>
                                                    <input type="text" className="form-control" name="lastName" value={formData.lastName} onChange={handleChange} />
                                                </div>
                                                
                                                <div className="mb-3">
                                                    <label><strong>Email</strong></label>
                                                    <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} />
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" className="btn btn-primary btn-block">Update</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {dataFetched ? displayData() : <div>Loading...</div>}
            <ToastContainer />
        </>
    );
};

export default AdminProfile;