import React from 'react';
import TermCondition from './TermsConditions/termsconditions';

const TermsConditions = () => {
  return (
    <>
      <TermCondition />
    </>
  )
}

export default TermsConditions
