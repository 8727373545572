import React from 'react'
import Privacy from './PrivacyPolicy/privacypolicy'

const PrivacyPolicy = () => {
  return (
    <>
    <Privacy/>
      
    </>
  )
}

export default PrivacyPolicy
