import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class CkEditor extends Component {
    render() {
        const { title, data, onChange } = this.props;

        return (
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card b-0">
                            <div className="card-header">
                                <h4 className="card-title">{title}</h4> {/* Use the title prop here */}
                            </div>
                            <div className="card-body custom-ekeditor">
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={data}
                                    onReady={editor => {
                                       
                                    }}
                                    onChange={(event, editor) => {
                                        if (editor) {
                                            const data = editor.getData();
                                            
                                            onChange(data);
                                        } else {
                                            console.error('Editor instance not available during onChange');
                                        }
                                    }}
                                    onBlur={(event, editor) => {
                                        // console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CkEditor;
