import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ChangePassword = () => {
    const [oldpassword, setoldpassword] = useState("");
    const [newpassword, setnewpassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showoldpassword, setShowoldpassword] = useState(false); // State to toggle visibility of old password
    const [shownewpassword, setShownewpassword] = useState(false); // State to toggle visibility of new password
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle visibility of confirm password
    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();
    
        // Check if new password matches confirm password
        if (newpassword !== confirmPassword) {
            alert("New password and confirm password do not match");
            return;
        }
    
        try {
            // Make API request to change password
            const token = localStorage.getItem("token");
            const response = await axios.post(
                "https://api.engageathlete.com/api/v1/changepassword",
                {
                    oldpassword,
                    newpassword
                },
                {
                    headers: {
                        'authorization': `${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                }
            );
    
            // Handle successful response, you may redirect to dashboard or show a success message
            console.log(response.data); // Log the response data
            toast.success("Password updated successfully");
            navigate("/dashboard"); // Redirect to dashboard
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error("Old password is incorrect");
            } else {
                console.error("Error changing password:", error);
                toast.error("Failed to update password. Please try again.");
            }
        }
    };
    

    return (
        <div className="authincation">
            <div className="container">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <h4 className="text-center mb-4">Change Password</h4>
                                        <form onSubmit={submitHandler}>
                                            <div className="mb-3">
                                                <label><strong>Old Password</strong></label>
                                                <div className="input-group">
                                                    <input
                                                        type={showoldpassword ? "text" : "password"}
                                                        className="form-control"
                                                        value={oldpassword}
                                                        onChange={(e) => setoldpassword(e.target.value)}
                                                    />
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        type="button"
                                                        onClick={() => setShowoldpassword(!showoldpassword)}
                                                    >
                                                        <i className={`bi ${showoldpassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label><strong>New Password</strong></label>
                                                <div className="input-group">
                                                    <input
                                                        type={shownewpassword ? "text" : "password"}
                                                        className="form-control"
                                                        value={newpassword}
                                                        onChange={(e) => setnewpassword(e.target.value)}
                                                    />
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        type="button"
                                                        onClick={() => setShownewpassword(!shownewpassword)}
                                                    >
                                                        <i className={`bi ${shownewpassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label><strong>Confirm Password</strong></label>
                                                <div className="input-group">
                                                    <input
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        className="form-control"
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    />
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        type="button"
                                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    >
                                                        <i className={`bi ${showConfirmPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary btn-block">Update</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
