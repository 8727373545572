import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import stateList from '../../states.json';

import { setFieldValue } from 'formik';
const BillingstateOffcanvas = forwardRef((props, ref) => {
    const [addEmploye, setAddEmploye] = useState(false);

    useImperativeHandle(ref, () => ({
        showEmployeModal() {
            setAddEmploye(true);
        }
    }));

    const initialValues = {
        stateName: '',
        tax: '0.00',
    };
    const validationSchema = Yup.object().shape({
        stateName: Yup.string()
            .required('Billing State is required')
            .max(15, 'State must be within 15 characters'),
        tax: Yup.number()
            .required('Tax is required')
            .min(0, 'Tax must be greater than or equal to 0')
            .max(100, 'Tax must be less than or equal to 100')
            .typeError('Tax must be a number')
            .test(
                'decimal-places',
                'Tax must have up to 3 decimal places',
                value => (value === undefined || /^\d+(\.\d{1,3})?$/.test(value))
            ),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        const data = {
            stateName: values.stateName,
            tax: parseFloat(values.tax).toFixed(3)
        };

        const token = localStorage.getItem('token');
        const endpoint = 'https://api.engageathlete.com/api/v1/addbillingstate';

        axios.post(endpoint, data, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(response => {
                console.log('Data added successfully:', response.data);
                setAddEmploye(false);
                toast.success('Data added successfully');
                // Fetch updated data after adding
                axios.get('https://api.engageathlete.com/api/v1/getallbillingstate', {
                    headers: {
                        'authorization': `${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        props.updateTableData(response.data.data); // Update parent component's state with fetched data
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                        toast.error('Error fetching updated data');
                    });
            })
            .catch(error => {
                console.error('Error adding data:', error);
                const message = error.response && error.response.data && error.response.data.message
                    ? error.response.data.message
                    : 'Error submitting form';
                toast.error(message); // Displaying the specific error message from the server if available
            })
            .finally(() => {
                setSubmitting(false);
            });
    };



    return (
        <>
            <Offcanvas show={addEmploye} onHide={() => setAddEmploye(false)} className="offcanvas-end customeoff " placement='end'>
                <div className="offcanvas-header profile-head-h">
                    <h5 className="modal-title text-white" id="#gridSystemModal">{props.Title}</h5>
                    <button type="button" className="btn-close" onClick={() => setAddEmploye(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting, setFieldValue, handleChange, handleBlur }) => (
                                <Form>
                                    <div className="row">

                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="stateName" className="form-label">Billing State <span className="text-danger">*</span></label>
                                            <Field as="select" name="stateName" className="form-control" id="stateName">
                                                <option value="" >Select State</option>
                                                {stateList.map(state => (
                                                    <option key={state.name} value={state.name}>{state.name}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="stateName" component="div" className="text-danger" />
                                        </div>

                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="tax" className="form-label">Tax <span className="text-danger">*</span></label>
                                            <div className="input-group">
                                                <Field
                                                    type="number"
                                                    step="0.01" // Allow up to two decimal places
                                                    className="form-control"
                                                    id="tax"
                                                    name="tax"
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                    }}
                                                    onBlur={(e) => {
                                                        const { value } = e.target;
                                                        if (value !== '') {
                                                            const formattedValue = parseFloat(value);
                                                            setFieldValue('tax', formattedValue);
                                                        }
                                                        handleBlur(e);
                                                    }}
                                                />







                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                            </div>
                                            <ErrorMessage name="tax" component="div" className="text-danger" />
                                        </div>
                                    </div>
                                    <div>
                                        <button type="submit" className="btn btn-primary me-1" disabled={isSubmitting}>Submit</button>
                                        <Link to="#" onClick={() => setAddEmploye(false)} className="btn btn-danger light ms-1">Cancel</Link>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Offcanvas>
        </>
    );
});

export default BillingstateOffcanvas;
