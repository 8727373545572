import React, { useState, forwardRef, useImperativeHandle, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';

const EditClubOffcanvas = forwardRef((props, ref) => {
  const [ageOptions, setAgeOptions] = useState([]);
  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [editClub, setEditClub] = useState(false);
  const [galleryId, setGalleryId] = useState(null);
  const [profile, setProfile] = useState(null);
  const [formData, setFormData] = useState();
  const [sportData, setSportData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [clubId, setClubId] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState([]);
  const [selectedCityIds, setSelectedCityIds] = useState([]);
  useImperativeHandle(ref, () => ({
    showEmployeModal(id) {
      console.log('subscription ID:', id);
      setEditClub(true);
      fetchClubById(id);
    }
  }));

  useEffect(() => {
    fetchAge();
    fetchStates();
  }, []);
  const fetchStates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api.engageathlete.com/api/v1/getallstates', {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });
      const stateData = response.data.data.map(state => ({
        value: state.id,
        label: state.name
      }));
      setState(stateData);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  useEffect(() => {
   
    setCities([]);
    if (selectedStateId) {
      fetchCitiesByState(selectedStateId);
    }
  }, [selectedStateId]);
  console.log(selectedStateId);
  const fetchCitiesByState = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://api.engageathlete.com/api/v1/getallcities?stateId=${id}`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });
      console.log("city", response.data.citiesData);
      if (response.data.citiesData && response.data.citiesData.length > 0) {
        // Flatten the citiesData array directly
        const citiesData = response.data.citiesData.flat().map(city => ({
          value: city.id,
          label: city.name
        }));

        setCities(citiesData);
      } else {
        console.error('Error fetching cities: citiesData is undefined or empty');
      }



    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };
  const fetchAge = async () => {
    try {
      const token = localStorage.getItem('token');
      const id = localStorage.getItem('id');
      console.log(id);
      const response = await axios.get(`https://api.engageathlete.com/api/v1/getsettings/1`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });

      const fromAge = parseInt(response.data.data.fromAge); // Ensure fromAge is parsed as an integer
      const toAge = parseInt(response.data.data.toAge); // Ensure toAge is parsed as an integer
      console.log("From Age:", fromAge);
      console.log("To Age:", toAge);

      const options = [];
      for (let i = fromAge; i <= toAge; i++) {
        options.push({ value: i.toString(), label: i.toString() }); // Create object with value and label properties
      }
      setAgeOptions(options);
      console.log("age", ageOptions);
      console.log("Age Options:", ageOptions);

    } catch (error) {
      console.error('Error fetching age:', error);

    }
  };
  const fetchClubById = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://api.engageathlete.com/api/v1/getacademies/${id}`,


        {
          headers: {
            authorization: ` ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = response.data.data;
      console.log("Fetched club data:", responseData);
      const cities = responseData.citiesData.map(city => ({
        value: city.id,
        label: city.name
      }));

      const stateIds = responseData.stateData.map(state => state.id).join(",");
      const states = responseData.stateData.filter(state => stateIds.includes(String(state.id))).map(state => ({
        value: state.id,
        label: state.name
      }));
      console.log(states);
      const profileId = responseData?.userData?.id;
      setClubId(profileId);
      const fetchedGalleryId = responseData?.galleryData?.[0]?.id;
      setGalleryId(fetchedGalleryId);

      setProfile(responseData?.galleryData?.[0]?.fileLocation);
      // Mapping fetched data to match formData structure

      const mappedData = {

        id: responseData.userData.id,
        firstName: responseData.userData.firstName,
        lastName: responseData.userData.lastName,
        email: responseData.userData.email,
        profileImg: responseData.userData.profileImg,
        academieName: responseData.academieData.academieName,
        title: responseData.academieData.title,
        phone: responseData.academieData.phone,
        bio: responseData.academieData.bio,
        city: cities,
        state: states,
        ageYouCoach: responseData.academieData.ageYouCoach.split(',').map(age => ({ value: age, label: age })),
        genderYouCoach: responseData.academieData.genderYouCoach.split(',').map(gender => ({ value: gender, label: gender })),
        leagueName: responseData.academieData.leagueName,
        instagramLink: responseData.academieData.instagramLink,
        twitterLink: responseData.academieData.twitterLink,
        websiteLink: responseData.academieData.websiteLink,
        sportName: responseData.sportData.sportName,
        subscriptionId: responseData.academieData.subscriptionId,
        isActive: responseData.academieData.isActive,
        isApprove: responseData.academieData.isApprove,
        galleryId: responseData.academieData.galleryId,
      };

      setFormData(mappedData); // Update form data
      console.log(formData);
    } catch (error) {
      console.error("Error fetching club data:", error);
      toast.error("Failed to fetch club data!");
    }
  };
  //sport data
  useEffect(() => {
    const token = localStorage.getItem('token');

    axios.get('https://api.engageathlete.com/api/v1/getallsports', {
      headers: {
        'authorization': `${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setSportData(response.data);
      })
      .catch(error => {
        console.error('Error fetching sportData data:', error);
      });
  }, []);
  // Define validation schema
  const validationSchema = Yup.object().shape({
    academieName: Yup.string()

      .max(25, 'Too Long!')
      .required('Required'),
    firstName: Yup.string()

      .max(15, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()

      .max(15, 'Too Long!')
      .required('Required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),

    city: Yup.string()
      .required('Required'),
    state: Yup.string()
      .required('Required'),
    phone: Yup.number()
      .integer('Must be an integer')
      .required('Required'),
    title: Yup.string()
      .required('Required'),
    bio: Yup.string()
      .required('Required')
      .max(500, 'Achievements must be at most 500 characters'), // Adjust the character limit as needed

    leagueName: Yup.string()
      .required('Required'),
    ageYouCoach: Yup.number()
      .required('Required'),
    genderYouCoach: Yup.string()
      .required('Required'),
    instagramLink: Yup.string()
      .url('Invalid URL')
      .required('Required'),
    twitterLink: Yup.string()
      .url('Invalid URL')
      .required('Required'),
    websiteLink: Yup.string()
      .url('Invalid URL')
      .required('Required'),
    sportId: Yup.string()
      .required('Required'),
    subscriptionId: Yup.string()
      .required('Required'),
  });
  const handleSubmit = async (e, values) => {
    e.preventDefault();
    const stateIds = values.state.map(state => state.value).join(',');
    const cityIds = values.city.map(city => city.value).join(',');
    const ageYouCoach = values.ageYouCoach.map(item => item.value).join(',');
    const genderYouCoach = values.genderYouCoach.map(item => item.value).join(',');
    try {
      const token = localStorage.getItem('token');
      const formattedValues = {
        ...values,
        state: stateIds,
        city: cityIds,
        ageYouCoach,
        genderYouCoach
      };

      if (galleryId) {
        formattedValues.galleryId = galleryId;
      }
      const response = await axios.post(`https://api.engageathlete.com/api/v1/updateacademies`, formattedValues, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'authorization': `${token}`
        }
      });
      console.log('Response:', response.data);
      if (response.data.status === 200 && response.data) {
        console.log("Response:", response.data);

        if (selectedFile && !galleryId) {


          console.log("Profile Uploading")
          const fileFormData = new FormData();
          fileFormData.append('userId', clubId);
          fileFormData.append('fileLocation', selectedFile, selectedFile.name);
          fileFormData.append('description', "New Academy Profile Image");
          fileFormData.append('isActive', "true");
          fileFormData.append('fileType', "Profile Image");
          fileFormData.append('isApproved', "true");
          console.log(fileFormData);

          await axios.post('https://api.engageathlete.com/api/v1/upload-singlefile', fileFormData, {
            headers: {
              'authorization': `${token}`,
              'Content-Type': 'multipart/form-data',
            }
          });
          console.log("File uploaded successfully!");
          toast.success("File uploaded successfully!");
        }

        // Update existing profile picture
        if (selectedFile && galleryId) {
          const fileFormData = new FormData();
          fileFormData.append('id', galleryId);
          fileFormData.append('userId', response.data);
          fileFormData.append('fileLocation', selectedFile, selectedFile.name);
          fileFormData.append('description', "Updated Profile Image");
          fileFormData.append('isActive', "true");
          fileFormData.append('fileType', "Profile Image");
          fileFormData.append('isApproved', "true");

          await axios.post('https://api.engageathlete.com/api/v1/update-file', fileFormData, {
            headers: {
              'authorization': `${token}`,
              'Content-Type': 'multipart/form-data',
            }
          });
          console.log("File updated successfully!");
          toast.success("File updated successfully!");
        }
        toast.success('Profile updated successfully!');

        props.onClubAdded();

        setEditClub(false);
      }

    } catch (error) {
      console.error('Error updating subscription:', error);
      toast.error('Failed to update subscription!');
    }

  };


  const initialValues = formData || {
    firstName: '',
    lastName: '',
    email: '',
    profileImg: '',
    academieName: '',
    title: '',
    bio: '',
    city: [],
    state: [],
    leagueName: '',
    instagramLink: '',
    twitterLink: '',
    sportId: 0,
    subscriptionId: 0,
    isActive: "",
    isApproved: "",
    ageYouCoach: formData ? formData.ageYouCoach : [],
    genderYouCoach: formData ? formData.genderYouCoach : [],

  };
  const handleStateChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map(option => option.value);
    setSelectedStateId(selectedIds);
    setFormData(prevData => ({ ...prevData, state: selectedOptions }));
    console.log('Selected State IDs:', selectedIds);
  };

  const handleCityChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map(option => option.value);
    setSelectedCityIds(selectedIds);
    setFormData(prevData => ({ ...prevData, city: selectedOptions }));
    console.log('Selected City IDs:', selectedIds);
  };
  return (
    <>
      <Offcanvas
        show={editClub}
        onHide={() => setEditClub(false)}
        className="offcanvas-end customeoff"
        placement="end"
      >
        <div className="offcanvas-header profile-head-h">
          <h5 className="modal-title text-white" id="#gridSystemModal">
            {props.Title}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setEditClub(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">

            <Formik
              initialValues={formData || initialValues} // Use formData if available, otherwise use initialValues
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form onSubmit={(e) => {
                  e.preventDefault(); // Prevent default form submission behavior
                  handleSubmit(e, values);
                }}>
                  <div className="row">
                    <label>Profile Picture</label>
                    <div className="dz-default dlab-message upload-img mb-3">

                      <div className="col-xl-6 mb-3">
                        <div className="col-xl-6 mb-3">
                          {profile && (
                            <img src={profile} alt="Selected File" style={{ maxWidth: '100px', marginTop: '10px' }} />
                          )}
                        </div>
                        <Field
                          type="file"
                          name="profileImg"
                          className="form-control"
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            setSelectedFile(file);

                            const reader = new FileReader();
                            reader.onload = () => {
                              setProfile(reader.result);
                            };
                            reader.readAsDataURL(file);
                          }}
                        />
                      </div>


                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="academieName" className="form-label">
                        Club & Academy Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="academieName"
                        className="form-control"
                        id="academieName"
                        placeholder="Club & Academy Name"
                      />
                      <ErrorMessage
                        name="academieName"
                        component="div"
                        className="text-danger"
                      />
                    </div>


                    <div className="col-xl-6 mb-3">
                      <label htmlFor="title" className="form-label">
                        Contact Title <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="title"
                        className="form-control"
                        id="title"
                        placeholder=" Contact Title"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="firstName"
                        className="form-label"
                      >
                        Contact First Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="firstName"
                        className="form-control"
                        id="firstName"
                        placeholder="First Name"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="lastName"
                        className="form-label"
                      >
                        Contact Last Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="lastName"
                        className="form-control"
                        id="lastName"
                        placeholder="Last Name"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-danger"
                      />
                    </div>





                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="phone"
                        className="form-label"
                      >

                        Contact Number <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="phone"
                        name="phone"
                        className="form-control"
                        id="phone"
                        placeholder=""
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="email"
                        className="form-label"
                      >
                        {" "}
                        Email <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="twitterLink" className="form-label">
                        X Profile Link <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="twitterLink"
                        className="form-control"
                        id="twitterLink"
                        placeholder=""
                      />
                      <ErrorMessage
                        name="twitterLink"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="instagramLink" className="form-label">
                        Instagram Profile Link
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="instagramLink"
                        className="form-control"
                        id="instagramLink"
                        placeholder=""
                      />
                      <ErrorMessage
                        name="instagramLink"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="exampleFormControlInput4"
                        className="form-label"
                      >
                        Website Link <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="websiteLink"
                        className="form-control"
                        id="websiteLink"
                        placeholder=""
                      />
                      <ErrorMessage
                        name="websiteLink"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="isApprove" className="form-label">
                        Approval Status
                      </label>
                      <Field
                        as="select"
                        className="form-control"
                        id="isApprove"
                        name="isApprove"
                      >
                        <option value="Approve">Approve</option>
                        <option value="Pending">Pending</option>
                        <option value="Reject">Reject</option>

                      </Field>


                      <ErrorMessage name="isApprove" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="isActive" className="form-label">
                        Publish Status
                      </label>
                      <Field
                        as="select"
                        className="form-control"
                        id="isActive"
                        name="isActive"
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Field>



                      <ErrorMessage name="isActive" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-12 mb-3">
                      <label className="form-label">Description</label>
                      <Field name="bio">
                        {({ field, form }) => (
                          <div>
                            <CKEditor
                              editor={ClassicEditor}
                              data={field.value}
                              onChange={(event, editor) => {
                                form.setFieldValue(field.name, editor.getData());
                              }}
                            />
                            <ErrorMessage name="bio" component="div" className="text-danger" />
                          </div>
                        )}
                      </Field>

                    </div>
                    <h3 className='profile-head'>Sport Details</h3>

                    <div className="col-xl-6 mb-3">
                    <label htmlFor="sportId" className="form-label">
                      Primary Sport <span className="text-danger">*</span>
                    </label>
                    <Field as="select" className="form-control" id="sportId" name="sportId">
                      <option value="">Select a sport</option>
                      {sportData && sportData?.data?.map(sport => (
                        <option key={sport.id} value={sport.id}>{sport.sportName}</option>
                      ))}
                    </Field>
                    <ErrorMessage name="sportId" component="div" className="text-danger" />
                  </div>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="ageYouCoach" className="form-label">
                        Age You Interested In <span className="text-danger">*</span>
                      </label>
                      <Select
                        id="ageYouCoach"
                        name="ageYouCoach"
                        options={ageOptions}
                        isMulti
                        value={values.ageYouCoach}
                        onChange={(selectedOptions) => setFieldValue('ageYouCoach', selectedOptions)}

                        classNamePrefix="select"
                      />
                      <ErrorMessage name="ageYouCoach" component="div" className="text-danger" />
                    </div>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="genderYouCoach" className="form-label">
                        Gender You Interested In <span className="text-danger">*</span>
                      </label>
                      <Select
                        id="genderYouCoach"
                        name="genderYouCoach"
                        options={[
                          { value: 'Male', label: 'Male' },
                          { value: 'Female', label: 'Female' },
                          { value: 'Other', label: 'Other' },
                        ]}
                        isMulti
                        value={values.genderYouCoach}
                        onChange={(selectedOptions) => setFieldValue('genderYouCoach', selectedOptions)}
                        classNamePrefix="select"
                      />
                      <ErrorMessage name="genderYouCoach" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="state" className="form-label">
                        State You Interested In
                      </label>

                      <Select
                        id="state"
                        name="state"
                        options={states}
                        value={values.state}
                        classNamePrefix="select"
                        onChange={handleStateChange}
                        isMulti
                      />
                      <ErrorMessage name="state" component="div" className="text-danger" />
                    </div>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="city" className="form-label">
                        City You Interested In
                      </label>

                      <Select
                        id="city"
                        name="city"
                        options={cities}
                        value={values.city}
                        classNamePrefix="select"
                        isMulti
                        onChange={handleCityChange}
                      />
                      <ErrorMessage name="city" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-12 mb-3">
                      <label className="form-label">League Info</label>
                      <Field name="leagueName">
                        {({ field, form }) => (
                          <div>
                            <CKEditor
                              editor={ClassicEditor}
                              data={field.value}
                              onChange={(event, editor) => {
                                form.setFieldValue(field.name, editor.getData());
                              }}
                            />
                            <ErrorMessage name="leagueName" component="div" className="text-danger" />
                          </div>
                        )}
                      </Field>

                    </div>






                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary me-1" disabled={isSubmitting}>
                      Submit
                    </button>
                    <Link
                      to="#"
                      onClick={() => setEditClub(false)}
                      className="btn btn-secondary"
                    >
                      Cancel
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>

          </div>
        </div>
      </Offcanvas>
      <ToastContainer />
    </>
  );
});

export default EditClubOffcanvas;
