import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Formik, Field } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const InitialValues = () => {
  return {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    profileImg: "",
    age: "",
    gender: "",
    city: "",
    residentialState: '',
    school: "",
    bio: "",
    grade: "",
    achievements: "",
    parentFirstName: "",
    parentLastName: "",
    parentEmail: "",
    parentPhone: "",
    parentConsent: "",
    termsConditions: "",
    twitterLink: "",
    instagramLink: "",
    sportsId: "",
    subscriptionId: "",
    athleteSpecialty: "",
    currentAcademie: "",
    // isApprove: "",
    // isPublish: "",
  };
};
const validate = (values) => {
  let errors = {};
  if (values.profileImg) {
    const file = values.profileImg;
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];
    const maxFileSize = 200 * 1024; // 200 KB in bytes

    // Check if the file type is allowed
    if (!allowedTypes.includes(file.type)) {
      errors.profileImg = 'Invalid file type. Please upload a JPEG, JPG, PNG, or SVG file.';
    }

    // Check if the file size exceeds the limit
    if (file.size > maxFileSize) {
      errors.profileImg = 'File size exceeds the limit of 200 KB.';
    }
  }

  // First Name Validation
  if (!values.firstName) {
    errors.firstName = 'Required';
  } else if (values.firstName.length > 15) {
    errors.firstName = 'Too Long!';
  }

  // Last Name Validation
  if (!values.lastName) {
    errors.lastName = 'Required';
  } else if (values.lastName.length > 15) {
    errors.lastName = 'Too Long!';
  }

  // Email Validation
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email';
  }

  // Age Validation
  if (!values.age) {
    errors.age = 'Required';
  } else if (!Number.isInteger(Number(values.age))) {
    errors.age = 'Age must be an integer';
  } else if (Number(values.age) <= 0) {
    errors.age = 'Age must be a positive number';
  }

  // Gender Validation
  if (!values.gender) {
    errors.gender = 'Required';
  }

  // City Validation
  if (!values.city) {
    errors.city = 'Required';
  } else if (values.city.length > 15) {
    errors.city = 'Too Long!';
  }

  // Residential State Validation
  if (!values.residentialState) {
    errors.residentialState = 'Required';
  }

  // Parent First Name Validation
  if (!values.parentFirstName) {
    errors.parentFirstName = 'Required';
  } else if (values.parentFirstName.length > 15) {
    errors.parentFirstName = 'Too Long!';
  }

  // Parent Last Name Validation
  if (!values.parentLastName) {
    errors.parentLastName = 'Required';
  } else if (values.parentLastName.length > 15) {
    errors.parentLastName = 'Too Long!';
  }

  // Parent Email Validation
  if (!values.email) {
    errors.parentEmail = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.parentEmail = 'Invalid email';
  }

  if (!values.parentPhone) {
    errors.parentPhone = 'Required';
  } else if (!/^\d{10}$/.test(values.parentPhone)) {
    errors.parentPhone = 'Invalid phone number! Must be 10 digits long.';
  }
  // Parent Consent Validation
  if (!values.parentConsent) {
    errors.parentConsent = 'Required';
  }
  if (!values.termsConditions) {
    errors.termsConditions = 'Required';
  }


  // Sport ID Validation
  if (!values.sportsId) {
    errors.sportsId = 'Required';
  }
  //academy
  if (values.currentAcademie) {
    const wordCount = values.currentAcademie.trim().split(/\s+/).length;
    if (wordCount > 500) {
      errors.currentAcademie = 'Academy Details cannot exceed 500 words';
    }
  }
  // Bio Validation
  if (values.bio) {
    const wordCount = values.bio.trim().split(/\s+/).length;
    if (wordCount > 1000) {
      errors.bio = 'Bio cannot exceed 1000 words';
    }
  }

  // Achievements Validation
  if (values.achievements) {
    const wordCount = values.achievements.trim().split(/\s+/).length;
    if (wordCount > 500) {
      errors.achievements = 'Achievements cannot exceed 500 words';
    }
  }

  console.log(errors);
  return errors;
};


const AthleteOffcanvas = forwardRef((props, ref) => {
  const [apiError, setApiError] = useState('');

  const [addEmploye, setAddEmploye] = useState(false);
  const [selectedsportsId, setSelectedsportsId] = useState('');
  const [selectedStateId, setSelectedStateId] = useState('');
  const [athleteData, setathleteData] = useState([]);
  const [sports, setSports] = useState([]);
  const [age, setAge] = useState([]);
  const [maxage, setMaxAge] = useState([]);
  const [state, setState] = useState([]);
  const [profileFile, setprofileFile] = useState(null);
  const [ageOptions, setAgeOptions] = useState([]);
  const [athleteSpecialties, setAthleteSpecialties] = useState([]);
  const [showSelectInput, setShowSelectInput] = useState(true); // Initially set to true or false based on your requirement
  const [cities, setCities] = useState([]);

  useImperativeHandle(ref, () => ({
    showEmployeModal() {
      setAddEmploye(true)
    }
  }));

  useEffect(() => {
    fetchathlete();
    fetchSports();
    fetchStates();
    fetchAge();
    if (selectedsportsId) {
      setAthleteSpecialties([]);
      console.log(selectedsportsId);
      fetchAthleteSpecialties(selectedsportsId);
    }
  }, [selectedsportsId]);

  const fetchAthleteSpecialties = async (sportsId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getspecialitybysport?sportId=${sportsId}`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });

      console.log('Response from fetchAthleteSpecialties:', response);

      if (response.status === 404) {
        setAthleteSpecialties([]);
        console.log('No athlete specialties found for the selected sport.');
        setShowSelectInput(true);
      } else if (response.data && response.data.data && Array.isArray(response.data.data)) {
        const specialties = response.data.data.map(spec => ({
          id: spec.id,
          specialityTitle: spec.specialityTitle
        }));
        setAthleteSpecialties(specialties);
        console.log('Athlete specialties:', specialties);
        setShowSelectInput(false);
      } else {
        console.error('Error: Unable to find athlete specialties in the response data');
      }
    } catch (error) {
      console.error('Error fetching athlete specialties:', error);
    }
  };


  const fetchathlete = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallathlete`, {
        headers: {
          'authorization': `${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      setathleteData(response.data.data);
    } catch (error) {
      console.error('Error fetching promo codes:', error);
    }
  };


  const fetchSports = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallsports`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });

      setSports(response.data.data);
      console.log(response.data.data)
    } catch (error) {
      console.error('Error fetching sports:', error);
    }
  };

  const fetchAge = async () => {
    try {
      const token = localStorage.getItem('token');
      const id = localStorage.getItem('id');
      console.log(id);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getsettings/1`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });
      setMaxAge(response.data.data.toAge);

      const fromAge = parseInt(response.data.data.fromAge); // Ensure fromAge is parsed as an integer
      const toAge = parseInt(response.data.data.toAge); // Ensure toAge is parsed as an integer
      console.log("From Age:", fromAge);
      console.log("To Age:", toAge);

      const options = [];
      for (let i = fromAge; i < toAge; i++) {
        options.push(i.toString()); // Store age values as strings
      }
      setAgeOptions(options);
      console.log("age", ageOptions);
      setAge(ageOptions);
      console.log(ageOptions, "age");
      console.log(age); // Set ageOptions as the state
    } catch (error) {
      console.error('Error fetching age:', error);
     
    }
  };

  const fetchStates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallstates`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });
      setState(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };
  const fetchCitiesByState = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallcities?stateId=${id}`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });

      // Check if the response contains citiesData and if it's an array
      if (response.data && Array.isArray(response.data.citiesData)) {
        setCities(response.data.citiesData); // Set the entire array of cities
        console.log("city", response.data.citiesData); // Log the cities array for debugging
      } else {
        console.error('Error: citiesData is not an array or is undefined');
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };


  useEffect(() => {

    setCities([]);
    if (selectedStateId) {
      fetchCitiesByState(selectedStateId);
    }
  }, [selectedStateId]);
  //profile
  const handleProfileUpload = async (userId) => {
    try {
      const formData = new FormData();
      console.log(profileFile);
      formData.append('fileLocation', profileFile);
      formData.append('description', "Athlete Profile");
      formData.append('isActive', "true");
      formData.append('fileType', "Profile Image");
      formData.append('isApproved', "true");
      formData.append('userId', userId);
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload-singlefile`, formData, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'authorization': `${token}`,
        }
      });

      return response.data.data.id;

    } catch (error) {
      console.error('Error uploading Athlete Profile:', error);
      throw new Error('Error uploading Athlete Profile');
    }
  };

  const handleSubmit = (values) => {
    console.log(values);

    console.log("Submitting form with values:", values);

    const subm = async () => {
      try {


        const formData = new FormData();
        const token = localStorage.getItem('token');

        formData.append('firstName', values.firstName || "");
        formData.append('lastName', values.lastName || "");
        formData.append('email', values.email || "");
        formData.append('password', values.password || "");
        formData.append('age', values.age || "");
        formData.append('profileImg', values.profileImg || "");
        formData.append('gender', values.gender || "");
        formData.append('city', values.city || "");
        formData.append('residentialState', selectedStateId || "");
        formData.append('school', values.school || "");
        formData.append('bio', values.bio || "");
        formData.append('achievements', values.achievements || "");
        formData.append('grade', values.grade || "");
        formData.append('parentFirstName', values.parentFirstName || "");
        formData.append('parentLastName', values.parentLastName || "");
        formData.append('parentEmail', values.parentEmail || "");
        formData.append('parentPhone', values.parentPhone || "");
        formData.append('parentConsent', values.parentConsent || "");
        formData.append('twitterLink', values.twitterLink || "");
        formData.append('instagramLink', values.instagramLink || "");
        formData.append('sportsId', Array.isArray(values.sportsId) ? values.sportsId.join(',') : values.sportsId || "");
        formData.append('subscriptionId', values.subscriptionId || "");
        formData.append('athleteSpecialty', Array.isArray(values.athleteSpecialty) ? values.athleteSpecialty.map(opt => opt.value).join(',') : values.athleteSpecialty || "");
        formData.append('currentAcademie', values.currentAcademie || "");
        formData.append('termsCondition', values.termsConditions || "");
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin-addathlete`, formData, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            authorization: token ? `${token}` : '',
          }
        });
        if (response.status === 201) {
          const userId = response.data.data.id;

          console.log(userId);


          setAddEmploye(false);
          console.log('Response:', response.data);
          props.onAthleteUpdated();
          await handleProfileUpload(userId);
          props.onAthleteUpdated();
          toast.success(response.data.message);
          fetchathlete();

        } else {
          // Handle non-200 status codes here
          console.error('Error:', response.status);
        }
      } catch (error) {

        if (error.response && error.response.status === 400) {
          setApiError('User already exists');
        } else {
          toast.error('Error: ' + error.response.data.message);
        }
        console.error('Error:', error);

      }
    }
    subm()

  };
  const handleProfileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];
      const maxFileSize = 200 * 1024; // 200 KB in bytes
  
      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {
        console.log("Invalid file type. Please upload a JPEG, JPG, PNG, or SVG file.");
        toast.error("Invalid file type. Please upload a JPEG, JPG, PNG, or SVG file.");
        e.target.value = null;
        setPreviewImage(null);
        return;
      }
  
      // Check if the file size exceeds the limit
      if (file.size >= maxFileSize) {
        console.log("File size exceeds the limit of 200 KB.");
        toast.error("File size exceeds the limit of 200 KB.");
        e.target.value = null; // Reset the file input field
        setPreviewImage(null); // Clear the preview image
        return;
      }
  
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        if (width >= 290 && width <= 300 && height >= 290 && height <= 300) {
          // If dimensions are within the allowed range
          console.log("Selected file:", file);
          setprofileFile(file);
  
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(file);
        } else {
          // If dimensions are not within the allowed range
          console.log("Image dimensions should be between 290 and 300 pixels in both height and width.");
          toast.error("Image dimensions should be between 290 and 300 pixels in both height and width.");
          e.target.value = null;
          setPreviewImage(null);
        }
      };
      img.src = URL.createObjectURL(file);
    } else {
      console.log("No file selected.");
      setprofileFile(null);
      setPreviewImage(null);
    }
  };
  

  const [previewImage, setPreviewImage] = useState(null);


  return (
    <Offcanvas show={addEmploye} onHide={() => setAddEmploye(false)} className="offcanvas-end customeoff" placement='end'>
      <div className="offcanvas-header profile-head-h">
        <h3 className="modal-title text-white" id="#gridSystemModal">{props.Title}</h3>
        <button type="button" className="btn-close" onClick={() => setAddEmploye(false)}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <Formik
            initialValues={InitialValues}
            validate={validate}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}
            validateOnChange={true} // Add validateOnChange prop
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              handleSubmit,
              values,
              touched,
              errors // Destructure the errors object
            }) => (
              <form onSubmit={handleSubmit}>

                <div className="col-xl-6 mb-3">
                  <label>Profile Picture</label>
                  {previewImage && (
                    <div>
                      <img src={previewImage} className='mb-3' alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                    </div>
                  )}
                  <div className="dz-default dlab-message upload-img mb-3">
                    <input
                      type="file"
                      id="profileImg"
                      name="profileImg"
                      onChange={handleProfileChange}
                      className="form-control"
                    />
                    <p className="text-danger">{errors.profileImg}</p>
                    <p className="text-danger">Height & Width should be between 290 - 300  </p>
                    <p className="text-danger">only GIF , JPG , JPEG, PNG, or SVG images up to 200 KB. </p>
                  </div>
                </div>


                <div className="row">
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="firstName" className="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <Field
                      type="text"
                      name="firstName"
                      className="form-control"
                      id="firstName"
                      placeholder="First Name"

                    />
                    <p className='text-danger'>{errors.firstName}</p>
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="lastName" className="form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <Field
                      type="text"
                      name="lastName"
                      className="form-control"
                      id="lastName"
                      placeholder="Last Name"
                    />
                    <p className='text-danger'>{errors.lastName}</p>
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="age" className="form-label">
                      Age <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="age"
                      options={ageOptions.map(ageValue => ({ value: ageValue, label: ageValue }))}
                      classNamePrefix="react-select"
                      id="age"
                      onChange={option => setFieldValue('age', option.value)}
                      onBlur={() => setFieldTouched('age', true)}
                      value={values.age ? { value: values.age, label: values.age } : null}
                    />

                    <p className='text-danger'>{errors.age}</p>
                  </div>

                  <div className="col-xl-6 mb-3">
                    <label htmlFor="gender" className="form-label">
                      Gender <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="gender"
                      options={[
                        { value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' },
                        { value: 'Other', label: 'Other' }
                      ]}
                      classNamePrefix="react-select"
                      id="gender"
                      onChange={option => setFieldValue('gender', option.value)}
                      onBlur={() => setFieldTouched('gender', true)}
                      value={values.gender ? { value: values.gender, label: values.gender } : null}
                    />
                    <p className='text-danger'>{errors.gender}</p>
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="residentialState" className="form-label">
                      Residential State <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="residentialState"
                      options={state.map(state => ({ value: state.id, label: state.name }))}
                      classNamePrefix="react-select"
                      id="residentialState"
                      onChange={option => {
                        setSelectedStateId(option.value);
                        setFieldValue('residentialState', option.value)
                      }
                      }
                      onBlur={() => setFieldTouched('residentialState', true)}
                      value={values.residentialState ? { value: values.residentialState, label: state.find(st => st.id === values.residentialState)?.name } : null}
                    />
                    <p className='text-danger'>{errors.residentialState}</p>
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="city" className="form-label">
                      Residential City <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="city"
                      name="city"
                      options={cities.map(city => ({ value: city.id, label: city.name }))}
                      classNamePrefix="react-select"
                      onChange={option => setFieldValue('city', option.value)}
                      onBlur={() => setFieldTouched('city', true)}
                      value={values.city ? { value: values.city, label: cities.find(city => city.id === values.city)?.name } : null}
                    />
                    <p className='text-danger'>{errors.city}</p>
                  </div>



                  <div className="col-xl-6 mb-3">
                    <label
                      htmlFor="exampleFormControlInput6"
                      className="form-label"
                    >
                      Current School
                    </label>
                    <Field
                      type="text"
                      name="school"
                      className="form-control"
                      id="school"
                      placeholder="Current School"

                    />
                    <p className='text-danger'>{errors.school}</p>
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="grade" className="form-label">
                      Grade
                    </label>
                    <input
                      type="text"
                      name="grade"
                      className="form-control"
                      id="grade"
                      placeholder="Grade"
                      value={values.grade}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className='text-danger'>{errors.grade}</p>
                  </div>




                  <div className="col-xl-6 mb-3">
                    <label htmlFor="twitterLink" className="form-label">
                      X Profile Link
                    </label>
                    <Field
                      type="text"
                      name="twitterLink"
                      className="form-control"
                      id="twitterLink"
                      placeholder="X Profile Link"

                    />
                    <p className='text-danger'>{errors.twitterLink}</p>
                  </div>

                  <div className="col-xl-6 mb-3">
                    <label htmlFor="instagramLink" className="form-label">
                      Instagram Profile Link

                    </label>
                    <Field
                      type="text"
                      name="instagramLink"
                      className="form-control"
                      id="instagramLink"
                      placeholder="Instagram Profile Link"

                    />
                    <p className='text-danger'>{errors.instagramLink}</p>
                  </div>
                  <div className="col-xl-12 mb-3">
                    <label className="form-label col-lg-11">Bio</label>
                    <Field name="bio">
                      {({ field, form }) => (
                        <CKEditor
                          editor={ClassicEditor}
                          data={field.value || ''}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            form.setFieldValue('bio', data);
                          }}
                          onBlur={() => form.setFieldTouched('bio', true)}
                          onError={(error) => {
                            console.error('CKEditor Error:', error);
                          }}
                        />
                      )}
                    </Field>
                    <p className='text-danger'>{errors.bio}</p>

                  </div>
                  <h3 className='profile-head'>Sport Details</h3>

                  <div className="col-xl-6 mb-3">
                    <label htmlFor="sportsId" className="form-label">
                      Sports of Interest <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="sportsId"
                      options={sports.map(sport => ({ value: sport.id, label: sport.sportName }))}
                      classNamePrefix="react-select"
                      isMulti
                      id="sportsId"
                      onChange={option => {

                        setFieldValue('sportsId', option ? option.map(opt => opt.value) : []);
                        setSelectedsportsId(option ? option.map(opt => opt.value) : []);
                      }}
                      value={values.sportsId ? values.sportsId.map(id => ({ value: id, label: sports.find(sport => sport.id === id)?.sportName })) : []}
                    />
                    <p className='text-danger'>{errors.sportsId}</p>
                  </div>

                  <div className="col-xl-6 mb-3">
                    <label htmlFor="athleteSpecialty" className="form-label">
                      Speciality of Interest
                    </label>
                    <Select
                      name="athleteSpecialty"
                      options={athleteSpecialties.map(specialty => ({
                        value: specialty.id,
                        label: specialty.specialityTitle
                      }))}
                      classNamePrefix="react-select"
                      isMulti
                      id="athleteSpecialty"
                      onChange={(selectedOption) => {
                        const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
                        setFieldValue('athleteSpecialty', selectedValues.join(','));
                      }}
                    />
                    <p className="text-danger">{errors.athleteSpecialty}</p>
                  </div>


                  <div className="col-xl-12 mb-3">
                    <label htmlFor="currentAcademie" className="form-label">
                      Current Academy
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={values.currentAcademie || ''}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('currentAcademie', data);
                      }}
                      onBlur={() => setFieldTouched('currentAcademie', true)}
                      onError={error => {
                        console.error('CKEditor Error:', error);
                      }}
                    />
                    <p className='text-danger'>{errors.currentAcademie}</p>
                  </div>

                  <div className="col-xl-12 mb-3">
                    <label className="form-label col-lg-11">
                      Achievements
                    </label>
                    <Field name="achievements">
                      {({ field, form }) => (
                        <CKEditor
                          editor={ClassicEditor}
                          data={field.value || ''}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            form.setFieldValue('achievements', data);
                          }}
                          onBlur={() => form.setFieldTouched('achievements', true)}
                          onError={(error) => {
                            console.error('CKEditor Error:', error);
                          }}
                        />
                      )}
                    </Field>
                    <p className='text-danger'>{errors.achievements}</p>

                  </div>
                  <h3 className='profile-head'>Parent Details</h3>
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="parentFirstName" className="form-label">
                      Parent First name <span className="text-danger">*</span>
                    </label>
                    <Field
                      type="text"
                      name="parentFirstName"
                      className="form-control"
                      id="parentFirstName"
                      placeholder="Parent First name"

                    />
                    <p className='text-danger'>{errors.parentFirstName}</p>
                  </div>

                  <div className="col-xl-6 mb-3">
                    <label htmlFor="parentLastName" className="form-label">
                      Parent Last name <span className="text-danger">*</span>
                    </label>
                    <Field
                      type="text"
                      name="parentLastName"
                      className="form-control"
                      id="parentLastName"
                      placeholder="Parent Last name"

                    />
                    <p className='text-danger'>{errors.parentLastName}</p>
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="parentPhone" className="form-label">
                      Parent Contact Number
                      <span className="text-danger">*</span>
                    </label>
                    <Field
                      type="tel"
                      name="parentPhone"
                      className="form-control"
                      id="parentPhone"
                      placeholder="Parent Contact Number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                    />
                    
                      <p className='text-danger'>{errors.parentPhone}</p>
                 
                  </div>

                  <div className="col-xl-6 mb-3">
                    <label
                      htmlFor="exampleFormControlInput5"
                      className="form-label"
                    >
                      Parent Email<span className="text-danger">*</span>
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="Parent Email"

                    />
                    <p className='text-danger'>{errors.email}</p>
                  </div>

                  <div className="col-xl-6 col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Parental Consent</h4>
                      </div>
                      <div className="card-body">
                        <div className="basic-form">
                          <div className="">
                            <div className="form-check custom-checkbox mb-3">
                              <Field
                                type="checkbox"
                                name="parentConsent"
                                className="form-check-input"
                                id="parentConsent"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="parentConsent"
                              >
                                I agree to the
                              </label>
                              {' '}
                              <a target='_blank' href="https://engageathlete.com/terms-conditions/" rel="noreferrer">
                                Parent Consent
                              </a>
                            </div>

                            <p className='text-danger'>{errors.parentConsent}</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Terms Conditions</h4>
                      </div>
                      <div className="card-body">
                        <div className="basic-form">
                          <div className="">
                            <div className="form-check custom-checkbox mb-3">
                              <Field
                                type="checkbox"
                                name="termsConditions"
                                className="form-check-input"
                                id="termsConditions"
                              />

                              <label
                                className="form-check-label"
                                htmlFor="termsConditions"
                              >
                                I agree to the
                              </label>
                              {' '}
                              <a target='_blank' href="https://engageathlete.com/terms-conditions/" rel="noreferrer">
                                Terms & Conditions
                              </a>
                            </div>

                            <p className='text-danger'>{errors.termsConditions}</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary me-1"
                    onClick={handleSubmit}
                    disabled={isSubmitting || apiError}
                  >
                    Submit
                  </button>
                  <Link
                    to={"#"}
                    onClick={() => setAddEmploye(false)}
                    className="btn btn-danger light ms-1"
                  >
                    Cancel
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Offcanvas>
  );
});

export default AthleteOffcanvas;
