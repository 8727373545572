import React, { useState, forwardRef, useEffect, useImperativeHandle } from "react";
import { Link } from "react-router-dom";
import { Offcanvas, Spinner } from "react-bootstrap"; // Import Spinner
import axios from "axios";
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import stateList from '../../states.json';

const EditBillingState = forwardRef((props, ref) => {
  const [addEmploye, setAddEmploye] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // Control loading state

  useImperativeHandle(ref, () => ({
    showEmployeModal(Id) {
      fetchData(Id);
      setAddEmploye(true);
    },
  }));

  const fetchData = (Id) => {
    setLoading(true); // Start loading before the fetch
    const apiUrl = `https://api.engageathlete.com/api/v1/getbillingstate/${Id}`;
    const token = localStorage.getItem('token');
    axios.get(apiUrl, {
      headers: {
        'authorization': `${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      setData(response.data.data);  // Set data
      setLoading(false);  // Stop loading after data is fetched
    })
    .catch(error => {
      console.error('Error fetching billing state details:', error);
      setData(null);  // Ensure data is null if fetch fails
      setLoading(false);  // Stop loading on error
    });
  };

  const validationSchema = Yup.object().shape({
    stateName: Yup.string().required('Billing State is required'),
    tax: Yup.number()
      .required('Tax is required')
      .min(0, 'Tax must be greater than or equal to 0')
      .max(100, 'Tax must be less than or equal to 100')
      .typeError('Tax must be a number')
      .test('decimal-places', 'Tax must have up to 3 decimal places', value => 
        value === undefined || /^\d+(\.\d{1,3})?$/.test(value)),
  });

  if (loading) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
        
      </div>
    );
  }


  return (
    <Offcanvas show={addEmploye} onHide={() => setAddEmploye(false)} className="offcanvas-end customeoff" placement="end">
      <div className="offcanvas-header profile-head-h">
        <h5 className="modal-title text-white" id="#gridSystemModal">{props.Title}</h5>
        <button type="button" className="btn-close" onClick={() => setAddEmploye(false)}><i className="fa-solid fa-xmark"></i></button>
      </div>
      <div className="offcanvas-body">
        <Formik
          initialValues={{
            stateName: data ? data.stateName : '',
            tax: data ? data.tax : '',
            id: data ? data.id : '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            const token = localStorage.getItem('token');
            axios.post('https://api.engageathlete.com/api/v1/updatebillingstate', {
              stateName: values.stateName,
              tax: values.tax,
              id: values.id,
              isActive: "true"
            }, {
              headers: {
                'Accept': "application/json, text/plain, */*",
                'Content-Type': 'application/json',
                'authorization': `${token}`,
              }
            })
            .then(response => {
              toast.success("State updated successfully");
              props.onbillingstateUpdated();
              fetchData(values.id); 
            })
            .catch(error => {
              console.error('Failed to update state:', error);
              toast.error("Failed to update state details");
            })
            .finally(() => {
              setSubmitting(false);
              setAddEmploye(false);
            });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="row">
                <div className="col-xl-6 mb-3">
                  <label htmlFor="stateName" className="form-label">Billing State <span className="text-danger">*</span></label>
                  <Field as="select" name="stateName" className="form-control" id="stateName">
                    <option value="">Select State</option>
                    {stateList?.map((state) => (
                      <option key={state?.name} value={state?.name}>{state?.name}</option>
                    ))}
                  </Field>
                  <ErrorMessage name="stateName" component="div" className="text-danger" />
                </div>
                <div className="col-xl-6 mb-3">
                  <label htmlFor="tax" className="form-label">Tax <span className="text-danger">*</span></label>
                  <Field type="text" name="tax" className="form-control" />
                  <ErrorMessage name="tax" component="div" className="text-danger" />
                </div>
              </div>
              <button type="submit" disabled={isSubmitting} className="btn btn-primary me-1">Submit</button>
              <Link to="#" onClick={() => setAddEmploye(false)} className="btn btn-danger light ms-1">Cancel</Link>
            </Form>
          )}
        </Formik>
      </div>
    </Offcanvas>
  );
});

export default EditBillingState;
