import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MainPagetitle from "../../layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import axios from 'axios';
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import ViewAthleteTransaction from "../../constant/AthleteTransaction";
import DatePicker from 'react-datepicker';

const AthleteReport = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [statusPriority, setStatusPriority] = useState([]);
  const [data, setData] = useState([]);
  const [sports, setSports] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedStateIds, setSelectedStateIds] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [maxAge, setMaxAge] = useState(0);
  const [filters, setFilters] = useState({
    age: '',
    isPublish: '',
    gender: '',
    isSubscription: 'Active',
    residentialState: '',
    city: '',
    sportsId: '',
    startDate: '',
    endDate: '',
  });
  const [activePag, setActivePag] = useState(() => {
    const storedPage = sessionStorage.getItem('activePag');
    return storedPage ? parseInt(storedPage) : 0;
  });
  const sort = 10;

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
    fetchTableData({ ...filters, [name]: value });
  };

  const handleSelectChange = (selected, { name }) => {
    let value = '';

    if (selected) {
      if (Array.isArray(selected)) {
        value = selected.map(option => option.value).join(',');
      } else {
        value = selected.value;
      }
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));

    fetchTableData({ ...filters, [name]: value });
  };

  useEffect(() => {
    fetchStates();
    fetchSports();
    fetchTableData();
    fetchAge();
  }, []);
  const handleDateChange = (date, name) => {
    setFilters(prev => ({ ...prev, [name]: date }));
    fetchTableData({ ...filters, [name]: date });
  };
  const fetchAge = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getsettings/1`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });
      const fromAge = parseInt(response.data.data.fromAge);
      const toAge = parseInt(response.data.data.toAge);

      const options = [];
      for (let i = fromAge; i <= toAge; i++) {
        options.push({ value: i, label: i.toString() });
      }
      setMaxAge(toAge);
      setAgeOptions(options);
    } catch (error) {
      console.error('Error fetching age:', error);
    }
  };

  useEffect(() => {
    if (selectedStateIds.length > 0) {
      fetchCitiesByState(selectedStateIds.map(state => state.value).join(","));
    } else {
      setCities([]);
    }
  }, [selectedStateIds]);

  const fetchTableData = (updatedFilters = filters) => {
    const apiUrl = 'https://api.engageathlete.com/api/v1/athletepurchasereport';
    const token = localStorage.getItem('token');

    if (token) {
      axios.get(apiUrl, {
        headers: {
          'authorization': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        params: updatedFilters
      })
        .then(response => {
          setTableData(response.data.transactionHistories);
          setStatusPriority(response.data.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    } else {
      console.error('Token not found in local storage');
      setLoading(false);
    }
  };

  const fetchStates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallstates`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': token,
        }
      });
      setStates(response.data.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  const fetchCitiesByState = async (stateIds) => {
    try {
      const token = localStorage.getItem('token');
      setCities([]);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallcities`, {
        params: { stateId: stateIds },
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': token,
        }
      });
      setCities(response.data.citiesData);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fetchSports = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallsports`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': token,
        }
      });
      setSports(response.data.data);
    } catch (error) {
      console.error('Error fetching sports:', error);
    }
  };

  const [test, setTest] = useState(0);

  useEffect(() => {
    setData(document.querySelectorAll("#employee-tbl_wrapper tbody tr"));
  }, [test]);

  const onClickPage = (page) => {
    setActivePag(page);
    sessionStorage.setItem('activePag', page);
  };

  const pagination = useMemo(() => Array.from({ length: Math.ceil(data.length / sort) }, (_, index) => index + 1), [data]);

  const handleReset = () => {
    const defaultFilters = {
      
      age: '',
      isPublish: '',
      gender: '',
      isSubscription: '',
      residentialState: '',
      city: '',
      sportsId: '',
      startDate: '',
      endDate: '',
    };
    setFilters(defaultFilters);
    setSelectedStateIds([]);
    setSelectedCities([]);
    fetchTableData(defaultFilters);
  };

  const viewclub = useRef();


  const generateCSVData = () => {
    return tableData.map(user => ({
      firstName: user.user.firstName,
      lastName: user.user.lastName,
      parentFirstName: user.user.athletes[0]?.parentFirstName || '',
      parentLastName: user.user.athletes[0]?.parentLastName || '',
      age: user.user.athletes[0]?.age || '',
      gender: user.user.athletes[0]?.gender || '',
      sports: user.user.athletes[0]?.sportsData.map(sport => sport.sportName).join(', ') || '',
      city: user.user.athletes[0]?.cityData?.name || '',
      residentialState: user.user.athletes[0]?.residentialStateData?.name || '',
      email: user.user.email || '',
      subscriptionName: user.subscriptionName || '',
      startDate: user.startDate || '',
      endDate: user.endDate || '',
      renewDate: new Date(new Date(user.endDate).setDate(new Date(user.endDate).getDate() + 1)).toLocaleDateString("en-US") || '',
      subscriptionMonth: `${user.subscriptionMonth} Months` || '',
      totalAmount: user.totalAmount || '',
      discount: user.discount || '',
      processingTax: user.processingTax || '',
      convenienceTax: user.convenienceTax || '',
      serviceTax: user.serviceTax || '',
    }));
  };
  const formatDate = (date) => {
    if (!date) return '';
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  return (
    <>
      <div className="col-xl-12 text-end mt-3 d-flex">
        <div className="col-xl-10 text-end mb-3">
          <MainPagetitle
            mainTitle="Athlete Report"
            pageTitle="Athlete Report"
            parentTitle="Home"
          />
        </div>
        <div className="col-xl-2 text-end mb-3">
          <CSVLink
            data={generateCSVData()}
            filename={`athlete_report_${new Date().toLocaleDateString()}.csv`}
            className="btn btn-primary"
            target="_blank"
          >
            Export to CSV
          </CSVLink>
        </div>
      </div>
      <div className="">
        <div className="row">
          <div className="col-xl-12">
            <div className="filter cm-content-box box-primary">
              <Collapse in={open}>
                <div className="cm-content-body form excerpt">
                  <div className="card-body">
                    <div className="row filter-row">
                      <div className="col-xl-2 col-sm-6 mb-3">
                        <Select
                          options={ageOptions}
                          value={ageOptions.find(option => option.value === filters.age)}
                          onChange={(selected) => handleSelectChange(selected, { name: 'age' })}
                          isMulti
                          placeholder="Select Age"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6 mb-3">
                        <select
                          className="form-control mb-xl-0 mb-3"
                          name="gender"
                          value={filters.gender}
                          onChange={handleFilterChange}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Others</option>
                        </select>
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <Select
                          options={sports.map(sport => ({ value: sport.id, label: sport.sportName }))}
                          value={filters.sportsId ? filters.sportsId.split(',').map(id => ({ value: id, label: sports.find(sport => sport.id === parseInt(id))?.sportName })) : []}
                          onChange={(selected) => handleSelectChange(selected, { name: 'sportsId' })}
                          isSearchable
                          isMulti
                          placeholder="Select Sport"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <Select
                          isSearchable
                          options={states.map(state => ({ value: state.id, label: state.name }))}
                          className="custom-react-select"
                          name="residentialState"
                          value={selectedStateIds}
                          onChange={(selected) => {
                            setSelectedStateIds(selected);
                            handleSelectChange(selected, { name: 'residentialState' });
                          }}
                          isMulti
                          placeholder="Select State"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <Select
                          isSearchable
                          options={cities.map(city => ({ value: city.id, label: city.name }))}
                          className="custom-react-select"
                          name="city"
                          value={selectedCities}
                          onChange={(selected) => {
                            setSelectedCities(selected);
                            handleSelectChange(selected, { name: 'city' });
                          }}
                          isMulti
                          placeholder="Select City"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <DatePicker
                          selected={filters.startDate}
                          onChange={(date) => handleDateChange(date, 'startDate')}
                          className="form-control mb-xl-0 mb-3"
                          placeholderText="Start Date"
                          dateFormat="MM/dd/yyyy"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <DatePicker
                          selected={filters.endDate}
                          onChange={(date) => handleDateChange(date, 'endDate')}
                          className="form-control mb-xl-0 mb-3"
                          placeholderText="End Date"
                          dateFormat="MM/dd/yyyy"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <div className="filter-btn">
                          <button type="button" className="btn btn-secondary" onClick={handleReset}>
                            <i className="bi bi-arrow-clockwise me-1"></i> Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>

          <div
            id="employee-tbl_wrapper"
            className=" no-footer"
          >
            <table
              id="empoloyees-tblwrapper"
              className="table ItemsCheckboxSec dataTable no-footer mb-0"
            >
              <thead>

                <th>First Name</th>
                <th>Last Name </th>
                <th>Parent First Name </th>
                <th>Parent Last Name</th>
                <th>sports</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Phone Number</th>
                <th>Resendtial City</th>
                <th>Resendtial State</th>
                <th >Email</th>
                <th>Subscription Plan</th>
                <th>Subscription Start Date</th>
                <th>Subscription End Date</th>
                <th>Subscription Renewal Date</th>
                <th>Subscription Duration </th>
                <th>Subscription Amount </th>
                <th>Discount Amount </th>
                <th>Processing Fee</th>
                <th>Convenience Fee</th>
                <th>Service Fee</th>

                <th>Total Subscription Amount</th>
              </thead>
              <tbody>
                {tableData.map((user, index) => (
                  <tr key={index}>
                    <td> <span>{user.user.firstName}</span> </td>
                    <td> <span>{user.user.lastName}</span></td>
                    <td> <span>{user.user.athletes[0]?.parentFirstName} </span></td>
                    <td> <span>{user.user.athletes[0]?.parentLastName}</span></td>
                    <td>
                      <span style={{ whiteSpace: 'pre-wrap' }}>
                        {user.user.athletes[0]?.sportsData?.map((sport, sportIndex) => {
                          const sportName = sport.sportName;
                          const words = sportName.split(' ');
                          const wrappedSportName = words.reduce((result, word, index) => {
                            if (index > 0 && index % 2 === 0) {
                              return `${result},\n${word}`;
                            }
                            return result === '' ? word : `${result} ${word}`;
                          }, '');
                          return wrappedSportName;
                        }).join(',')}
                      </span>
                    </td>

                    <td> <span>{user.user.athletes[0]?.age}</span></td>
                    <td> <span>{user.user.athletes[0]?.gender}</span></td>
                    <td> <span>{user.user.athletes[0]?.parentPhone}</span></td>
                    <td> <span>{user.user.athletes[0]?.cityData?.name}</span></td>
                    <td> <span>{user.user.athletes[0]?.residentialStateData?.name}</span></td>
                    <td> <span>{user.user.email}</span></td>

                    <td> <span>{user.subscriptionName}</span></td>
                    <td className="text-center"><span>{formatDate(user.startDate)}</span></td>
                    <td className="text-center"><span>{formatDate(user.endDate)}</span></td>

                    <td className="text-center"> <span> {new Date(new Date(user.endDate).setDate(new Date(user.endDate).getDate() + 1)).toLocaleDateString("en-US")}</span></td>
                    <td className="text-center"> <span>{user.subscriptionMonth} Months</span></td>
                    <td className="text-center"> <span>{user.totalAmount}</span></td>
                    <td className="text-center"> <span>{user.discount}</span></td>
                    <td className="text-center"> <span>{user.processingTax}</span></td>
                    <td className="text-center"> <span>{user.convenienceTax}</span></td>
                    <td className="text-center"> <span>{user.serviceTax}</span></td>
                    <td className="text-center"> <span>{user.totalAmount}</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <ViewAthleteTransaction ref={viewclub} Title="Transaction Details" />
    </>
  );
};

export default AthleteReport;
