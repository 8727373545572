import React, { useState, useEffect } from "react";
import axios from 'axios';
import MainPagetitle from "../../layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import DatePicker from 'react-datepicker';



const roleOptions = [
  { value: "2", label: "Athlete" },
  { value: "4", label: "Clubs & Academy" },
];


const PromocodeReport = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [filterParams, setFilterParams] = useState({
    sportId: '',
    roleId: '',
    promocodeName: '',
    startDate: '',
    endDate: '',
    isEnable: '',
  });
  const [isFiltered, setIsFiltered] = useState(false);

  const fetchData = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/promocodepurchasereport`;
    const token = localStorage.getItem('token');
    const formattedFilters = {
      ...filterParams,
      startDate: formatForAPI(filterParams.startDate),
      endDate: formatForAPI(filterParams.endDate)
    };

    if (token) {
      axios.get(apiUrl, {
        headers: {
          'authorization': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        params: formattedFilters
      })
      .then(response => {
        setTableData(response.data.transactionHistories);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
    } else {
      console.error('Token not found in local storage');
      setLoading(false);
    }
  };



 

  const handleFilterReset = () => {
    setFilterParams({
      roleId: '',
      promocodeName: '',
      startDate: '',
      endDate: '',
      isEnable: ''
    });
    setIsFiltered(false);
    fetchData();
  };



  const generateCSVData = () => {
    return tableData.map((item) => ({
      transactionId: item.paymentTransactionId,
      userName: `${item.user.firstName} ${item.user.lastName}`,
      totalAmount: item.totalAmount,
      taxAmount: item.taxAmount,
      taxPercentage: item.taxPercentage,
      subtotalAmount: item.subtotalAmount,
      discount: item.discount,
      paymentStatus: item.paymentStatus,
      subscriptionName: item.subscriptionName,
      startDate: item.startDate,
      endDate: item.endDate,
    }));
  };

  const formatForAPI = (date) => {
    if (!date) return '';
    return new Date(date).toISOString().split('T')[0]; // yyyy-mm-dd
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterParams(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleDateChange = (date, name) => {
    setFilterParams(prevFilters => ({
      ...prevFilters,
      [name]: date
    }));
  };
  useEffect(() => {
    fetchData();
  }, [filterParams]);
  return (
    <>

      <div className="">
        <div className="row">
          <div className="col-xl-12">
            <div className="filter cm-content-box box-primary">
              <div className="content-title">
                <div className="cpa">
                  <MainPagetitle
                    mainTitle="Promo Report"
                    pageTitle={"Promo Report"}
                    parentTitle={"Home"}
                  />
                </div>
                <div className="col-xl-2 text-end mb-3">
                  <CSVLink
                    data={generateCSVData()}
                    filename={`club_report_${new Date().toLocaleDateString()}.csv`}
                    className="btn btn-primary"
                    target="_blank"
                  >
                    Export to CSV
                  </CSVLink>
                </div>
              </div>

              <Collapse in={open}>
              <div className="cm-content-body form excerpt">
                <div className="card-body">
                  <div className="row filter-row">
                    <div className="col-xl-2 col-sm-6 pl-0 pr-0">
                      <input
                        name="promocodeName"
                        type="text"
                        className="form-control mb-xl-0 mb-3"
                        placeholder="Promo Code Value"
                        value={filterParams.promocodeName}
                        onChange={handleFilterChange}
                      />
                    </div>
                    <div className="col-xl-2 col-sm-6 mb-xl-0 mb-3 pl-0 pr-0">
                      {/* Assuming roleOptions is defined */}
                      <Select
                        isSearchable={false}
                        options={roleOptions} // Define roleOptions as per your implementation
                        className="custom-react-select"
                        menuPortalTarget={document.body}
                        value={roleOptions.find(option => option.value === filterParams.roleId)}
                        onChange={selected => handleFilterChange({ target: { name: 'roleId', value: selected ? selected.value : '' } })}
                      />
                    </div>
                    <div className="col-xl-2 col-sm-6">
                      <DatePicker
                        selected={filterParams.startDate}
                        onChange={(date) => handleDateChange(date, 'startDate')}
                        className="form-control mb-xl-0 mb-3"
                        placeholderText="Start Date"
                        dateFormat="MM/dd/yyyy"
                      />
                    </div>
                    <div className="col-xl-2 col-sm-6">
                      <DatePicker
                        selected={filterParams.endDate}
                        onChange={(date) => handleDateChange(date, 'endDate')}
                        className="form-control mb-xl-0 mb-3"
                        placeholderText="End Date"
                        dateFormat="MM/dd/yyyy"
                      />
                    </div>
                    <div className="col-xl-2 col-sm-6 pl-0 pr-0">
                      <button
                        className="btn btn-secondary me-2"
                        title="Reset Filters"
                        type="button"
                        onClick={handleFilterReset}
                      >
                        <i className="fa fa-times me-1"></i> Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
            </div>
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <div></div>
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th >Promo Code Value</th>
                          <th >User First Name</th>
                          <th >User Last Name</th>
                          <th >Profile</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th >Discount(%)</th>
                          <th >Discount Value</th>

                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="8" className="text-center">
                              Loading...
                            </td>
                          </tr>
                        ) : tableData.length === 0 ? (
                          <tr>
                            <td colSpan="8" className="text-center">
                              No data found
                            </td>
                          </tr>
                        ) : tableData.map((item, index) => (
                          <tr key={index}>
                            <td >{item.promocode.promocodeName}</td>
                            <td >{item.user.firstName}</td>
                            <td >{item.user.lastName}</td>
                            <td >{item.promocode.role.roleName}</td>
                            <td >{item.promocode.startDate}</td>
                            <td >{item.promocode.endDate}</td>
                            <td >{item.promocode.discount}</td>
                            <td >{item.discount}</td>



                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {tableData.length} entries
                      </div>
                      {/* Pagination can be added here if needed */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromocodeReport;
