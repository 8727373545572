import React from 'react'
import Banner from './Contact/Banner'
import ContactInfo from './Contact/Contact-Info'

const ContactCms = () => {
  return (
    <>
    <Banner/>
    <ContactInfo/>
      
    </>
  )
}

export default ContactCms
