import React, { useContext, useEffect } from 'react';
import ProjectOverviewTabClub from './elements/ProjectOverviewTabClub';
import { ThemeContext } from "../../../context/ThemeContext";
import MainPagetitle from '../../layouts/MainPagetitle';
import CardWidget from './elements/CardWidget';
import ProjectOverviewTab from './elements/ProjectOverviewTab';


const Home = () => {
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home" />
			<div className="container-fluid">
				<CardWidget />
				<div className="row">
					<div className="col-xl-6 mt-50">
						<ProjectOverviewTab />
					</div>
					<div className="col-xl-6 mt-50">
						<ProjectOverviewTabClub />
					</div>
				</div>
			</div>
		</>
	)
}
export default Home;