import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Nav, Tab } from 'react-bootstrap';
import axios from 'axios';

const chartHeaderData = [
  { title: 'Year', type: 'year' },
  { title: 'All', type: 'All' },
];

const ProjectOverviewChart = () => {
  const chartRef = useRef();
  const [chartData, setChartData] = useState({
    series: [],
    labels: []
  });
  const [finalValues, setFinalValues] = useState({
    numberOfAthlete: 0,
    activeSubscriptions: 0,
    revenue: 0,
  });
  const options = {
    chart: {
      height: 300,
      type: 'line',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 1, 1],
      curve: 'straight',
      dashArray: [0, 0, 5]
    },
    legend: {
      fontSize: '13px',
      fontFamily: 'poppins',
      labels: {
        colors: '#888888',
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '18%',
        borderRadius: 6,
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        colorStops: [
          [
            {
              offset: 0,
              color: 'var(--primary)',
              opacity: 1
            },
            {
              offset: 100,
              color: 'var(--primary)',
              opacity: 1
            }
          ],
          [
            {
              offset: 0,
              color: '#3AC977',
              opacity: 1
            },
            {
              offset: 0.4,
              color: '#3AC977',
              opacity: .15
            },
            {
              offset: 100,
              color: '#3AC977',
              opacity: 0
            }
          ],
          [
            {
              offset: 0,
              color: '#FF5E5E',
              opacity: 1
            },
            {
              offset: 100,
              color: '#FF5E5E',
              opacity: 1
            }
          ],
        ],
        stops: [0, 100, 100, 100]
      }
    },
    colors: ["var(--primary)", "#3AC977", "#FF5E5E"],
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Overall'],
    markers: {
      size: 0
    },
    xaxis: {
      type: 'month',
      labels: {
        style: {
          fontSize: '13px',
          colors: '#888888',
        },
      },
    },
    yaxis: {
      min: 0,
      tickAmount: 4,
      labels: {
        style: {
          fontSize: '13px',
          colors: '#888888',
        },
        formatter: function (value) {
          return value + 'k';
        }
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y, { seriesIndex, dataPointIndex, w }) {
          if (seriesIndex === 1) { // If it's the revenue series
            return y + 'k';
          }
          return y;
        }
      }
    }
  }

  const fetchData = async (yearType) => {
    try {
      const token = localStorage.getItem('token');
      const currentYear = new Date().getFullYear();
      const year = yearType === 'All' ? 'All' : currentYear;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/academierevenuemonths?year=${year}`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });

      const { data } = response.data;

      if (data) {
        const labelsData = Object.keys(data).filter(key => key !== 'final');

        const series = [
          {
            name: 'Number of Academy',
            type: 'column',
            data: Object.values(data).map(monthData => monthData.Allacademie || 0)
          },
          {
            name: 'Revenue',
            type: 'area',
            data: Object.values(data).map(monthData => Math.round(monthData.revenue / 1000) || 0)
          },
          {
            name: 'Active Subscriptions',
            type: 'line',
            data: Object.values(data).map(monthData => monthData.academieActiveProfile || 0)
          }
        ];

        setChartData({
          series: series,
          labels: labelsData
        });

        chartRef.current.chart.ctx.updateSeries(series);
        const finalValues = data.final || {};
        console.log("finalvalues",finalValues);

        setFinalValues({
          numberOfAthlete: finalValues.Allacademie || 0,
          activeSubscriptions: finalValues.academieActiveProfile || 0,
          revenue: Math.round(finalValues.revenue ) || 0,
        });

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData('year'); // Fetch initial data for 'Year' view
  }, []);

  const dataSeries = (seriesType) => {
    fetchData(seriesType);
  };

  console.log("chartData.labels", chartData.labels);
  console.log("chartData.series", chartData.series);

  return (
    <>
      <Tab.Container defaultActiveKey={'Year'}>
        <div className="card-header border-0 pb-0 flex-wrap">
          <h4 className="heading mb-0">Athlete Revenue</h4>
          <Nav as="ul" className="nav nav-pills mix-chart-tab">
            {chartHeaderData.map((item, index) => (
              <Nav.Item as="li" className="nav-item" key={index}>
                <Nav.Link eventKey={item.title}
                  onClick={() => dataSeries(item.type)}
                >{item.title}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <div className="card-body  p-0">
          <div id="overiewChart">
            <ReactApexChart
              options={options}
              series={chartData.series}
              ref={chartRef}
              type="line"
              height={300}
            />
          </div>
          <div className="ttl-project">
            <div className="pr-data">
              <h5>{finalValues.numberOfAthlete}</h5>
              <span>Number of Athlete</span>
            </div>
            <div className="pr-data">
              <h5 className="text-primary">{finalValues.activeSubscriptions}</h5>
              <span>Active Subscriptions</span>
            </div>
            <div className="pr-data">
              <h5>${finalValues.revenue}k</h5>
              <span>Revenue</span>
            </div>
          </div>
        </div>
      </Tab.Container>
    </>
  );

}

export default ProjectOverviewChart;
