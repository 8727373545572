import React, { useEffect, useState } from "react";
import axios from 'axios';
import NoImage from "../../../../images/no-image.jpg";
import CkEditor from "../../Forms/CkEditor/CkEditor";
import PageTitle from "../../../layouts/PageTitle";
import swal from "sweetalert";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const OurPartners = () => {
    const [title, setTitle] = useState('');
    const [sectionContent, setSectionContent] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [banners, setBanners] = useState([]);
    const [localImages, setLocalImages] = useState([]);
    const [newFile, setNewFile] = useState(null);
    const [changedBanners, setChangedBanners] = useState([]);

    useEffect(() => {
        fetchPageData();
    }, []);

    const fetchPageData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token not found in localStorage');
                return;
            }

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/getpage/1`, {
                headers: {
                    'authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            const cmssections = response.data.data.cmssections;
            const section = cmssections.find(section => section.id === 7);
            if (section) {
                setTitle(section.title || '');
                setSectionContent(section.description || '');
                setShortDescription(section.shortDescription || '');
            }

            const cmsgalleries = response.data.data.cmsgalleries;
            const bannerData = cmsgalleries.filter(item => item.cmssectionId === 7);

            const newBanners = bannerData.map((banner) => ({
                fileTitle: banner.fileTitle || '',
                fileDescription: banner.fileDescription || '',
                fileLocation: banner.fileLocation,
                id: banner.id,
                cmId: banner.cmId,
            }));

            setBanners(newBanners);
            setLocalImages(Array(newBanners.length).fill(null));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const updatePageData = async (title, description, shortDescription) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token not found in localStorage');
                return;
            }

            const fetchedData = await axios.get(`${process.env.REACT_APP_API_URL}/getpage/1`, {
                headers: {
                    'authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            const sectionToUpdate = fetchedData.data.data.cmssections.find(section => section.id === 7);

            if (!sectionToUpdate) {
                console.error('Section with id 6 not found');
                return;
            }

            sectionToUpdate.title = title;
            sectionToUpdate.shortDescription = shortDescription;

            const updatedData = {
                ...fetchedData.data,
                cmssections: fetchedData.data.data.cmssections.map(section =>
                    section.id === 7 ? sectionToUpdate : section)
            };

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/updatepage`, updatedData, {
                headers: {
                    'authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                swal("Update successful", { icon: "success" });
            } else {
                swal("Failed to update", { icon: "error" });
            }

        } catch (error) {
            console.error('Error updating data:', error);
            swal("Failed to update", { icon: "error" });
        }
    };

    const getImageUrl = (banner, localImage) => {
        if (localImage) {
            return URL.createObjectURL(localImage);
        } else if (banner) {
            return banner.fileLocation;
        } else {
            return NoImage;
        }
    };

    const fileHandler = (event, index) => {
        const selectedFile = event.target.files[0];
        console.log("Selected File:", selectedFile);

        const img = new Image();
        img.src = URL.createObjectURL(selectedFile);

        img.onload = () => {
            if ((img.width < 200 || img.width > 250) && (img.height < 100 || img.height > 150 )) {
                swal("Invalid Image Dimensions", "Please select an image with width between 200 to 250, height between 100 to 150", "error");
            } else {
                const updatedLocalImages = [...localImages];
                updatedLocalImages[index] = selectedFile;
                setLocalImages(updatedLocalImages);

                // Track changed banners
                const updatedChangedBanners = [...changedBanners];
                if (!updatedChangedBanners.includes(banners[index])) {
                    updatedChangedBanners.push({ ...banners[index], fileLocation: selectedFile });
                }
                setChangedBanners(updatedChangedBanners);
            }
        };
    };

    const handleNewFileChange = (event) => {
        setNewFile(event.target.files[0]);
    };

    const handleAddImage = async () => {
        if (!newFile) {
            swal("Please select a file to upload.", { icon: "warning" });
            return;
        }

        const formData = new FormData();
        formData.append('fileType', 'Partner Image');
        formData.append('fileTitle', 'Partner Image');
        formData.append('fileDescription', '');
        formData.append('cmId', 1);
        formData.append('cmssectionId', 7);
        formData.append('fileLocation', newFile);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token not found in localStorage');
                return;
            }

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/addsectionimage`, formData, {
                headers: {
                    'authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 201) {
                fetchPageData();
                const updatedBanners = [...banners, {
                    fileTitle: 'Partner Image',
                    fileDescription: '',
                    fileLocation: response.data.fileLocation
                }];
                setBanners(updatedBanners);
                setLocalImages([...localImages, newFile]);
                setNewFile(null);
                swal("Image uploaded successfully", { icon: "success" });
            } else {
                swal("Failed to upload image", { icon: "error" });
            }

        } catch (error) {
            console.error('Error uploading file:', error);
            swal("Failed to upload image", { icon: "error" });
        }
    };

    const removeImage = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/deletepageimage/${id}`,
                {},
                { headers }
            );

            if (response.status === 200) {
                swal("Success", "Image removed successfully", "success");
                fetchPageData();
            } else {
                swal("Error", "Failed to remove Image", "error");
            }
        } catch (error) {
            swal("Error", "Failed to remove Image", "error");
            console.error('Error removing Image:', error);
        }
    };

    const handleSave = async () => {
        await updatePageData(title, sectionContent, shortDescription);

        if (changedBanners.length > 0) {
            await updateExactFiles(changedBanners);
        }
    };

    const updateExactFiles = async (changedBanners) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token not found in localStorage');
                return;
            }
            console.log("Image", changedBanners);

            const formData = new FormData();
            formData.append('cmsgalleries', JSON.stringify(changedBanners.map(banner => ({
                id: banner.id,
                cmId: banner.cmId,
            }))));
            changedBanners.forEach((banner) => { formData.append('fileLocation', banner.fileLocation); });
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/updateexactfiles`, formData, {
                headers: {
                    'authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                console.log("Exact files updated successfully");
            } else {
                console.error("Failed to update exact files");
            }

        } catch (error) {
            console.error('Error updating exact files:', error);
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="h-auto">
                            <div className="card-body p-2">
                                <div className="row">
                                    <div className="col-lg-10">
                                        <PageTitle activeMenu="Our Partner" motherMenu="CMS" />
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="content-title border-bot d-flex">
                                            <OverlayTrigger
                                                placement="left"
                                                overlay={<Tooltip id={`tooltip-dimensions-BANNER`}>Image dimensions - 200-250px in width & 100-90 pxin height.
                                                    <br></br>File Size should be under 200KB
                                                </Tooltip>}
                                            >
                                                <div className="d-flex  mx-2 align-items-center">
                                                    <i class="bi bi-question-circle"></i>
                                                </div>
                                            </OverlayTrigger>
                                            <button type="button" className="btn btn-secondary light my-2" onClick={handleSave}>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <CkEditor
                                    title="Title"
                                    data={title}
                                    onChange={(data) => setTitle(data)}
                                />
                                <CkEditor
                                    title="Description"
                                    data={shortDescription}
                                    onChange={(data) => setShortDescription(data)}
                                />
                                <div className="card-body">
                                    <div className="avatar-upload d-flex align-items-center justify-content-center flex-wrap">
                                        {banners.map((banner, index) => (

                                            <div key={index} className="col-xl-3 mb-3">
                                                <div className="avatar-preview d-flex justify-content-center">
                                                    <div
                                                        id={`imagePreview${index}`}
                                                        style={{
                                                            backgroundImage: `url(${getImageUrl(banner, localImages[index])})`,
                                                            width: "260px",
                                                            height: "50px",
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center",
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="change-btn d-flex align-items-center justify-content-center">
                                                    <input
                                                        type="file"
                                                        onChange={(e) => fileHandler(e, index)}
                                                        id={`OurPartners${index}`}
                                                        className="form-control d-none"
                                                        accept="image/*"
                                                    />
                                                    <label
                                                        htmlFor={`OurPartners${index}`}
                                                        className="btn btn-primary ms-0 mb-0"
                                                    >
                                                        +
                                                    </label>
                                                    <button
                                                        className="btn btn-danger mt-0"
                                                        onClick={() => removeImage(banner.id)}
                                                        style={{ marginLeft: "10px" }}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="col-xl-3 mb-3">
                                            <div className="avatar-preview d-flex justify-content-center">
                                                <div
                                                    id={`imagePreview${banners.length}`}
                                                    style={{
                                                        backgroundImage: `url(${newFile ? URL.createObjectURL(newFile) : NoImage})`,
                                                        width: "260px",
                                                        height: "50px",
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center",
                                                    }}
                                                ></div>
                                            </div>
                                            <div className="content-title border-bot text-center">
                                                <input
                                                    type="file"
                                                    onChange={handleNewFileChange}
                                                    id="addImageFile4"
                                                    className="form-control d-none"
                                                    accept="image/*"
                                                />
                                                <label
                                                    htmlFor="addImageFile4"
                                                    className="btn btn-secondary light my-2 "
                                                    style={{ marginRight: "15px" }}
                                                >
                                                    +
                                                </label>
                                                <button type="button" className="btn btn-secondary light my-2" onClick={handleAddImage}>
                                                    save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OurPartners;
