import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Offcanvas } from "react-bootstrap";
import parse from 'html-react-parser';
import axios from "axios";
import { Spinner, Form } from "react-bootstrap";
import noimage from '../../images/noprofile/noprofiel.png'
const stripHtmlTags = (html) => {
  const parsedHtml = parse(html);
  const styledHtml = React.Children.map(parsedHtml, (child) => {

    if (React.isValidElement(child)) {

      const style = child.props.style;

      if (style) {
        const styleString = Object.entries(style)
          .map(([key, value]) => `${key}:${value}`)
          .join(';');
        return React.cloneElement(child, { style: styleString });
      }
    }
    return child;
  });

  return styledHtml;
};
const ViewAthleteOffcanvas = forwardRef((props, ref) => {
  const [showModal, setShowModal] = useState(false);
  const [athleteDetails, setAthleteDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const hasHeadshots = athleteDetails?.galleryData?.some(item => item?.fileType?.trim().toLowerCase() === "headshot image");

  useImperativeHandle(ref, () => ({
    showEmployeModal(athleteId) {
      fetchAthleteDetails(athleteId);
      setShowModal(true);
    },
    hideEmployeModal() {
      setShowModal(false);
    },
  }));

  const fetchAthleteDetails = (athleteId) => {
    setLoading(true);
    const apiUrl = `https://api.engageathlete.com/api/v1/getathlete/${athleteId}`;
    const token = localStorage.getItem("token");

    if (token) {
      axios
        .get(apiUrl, {
          headers: {
            authorization: ` ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setAthleteDetails(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching athlete details:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("Token not found in local storage");
      setLoading(false);
    }
  };
  console.log("athleteDetails", athleteDetails);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <Spinner className="text-center" animation="border" variant="primary" />
      </div>
    );
  }
  return (
    <>
      <Offcanvas
        show={showModal}
        onHide={() => setShowModal(false)}
        className="offcanvas-end customeoff"
        placement="end"
      >
        {loading ? (
          <p>Loading...</p>
        ) : athleteDetails ? (
          <>
            <div className="offcanvas-header profile-head-h">
              <h5 className="modal-title text-white" id="#gridSystemModal">
                {props.Title}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowModal(false)}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <Offcanvas.Body>

              <>
                <div className="container">
                  <form>
                    <div className="row d-flex justify-content-around">
                      <div className="col-xl-12 mb-3 view-detail-table">
                        <h3 className='profile-head'>Profile Details</h3>
                        <div className="col-xl-12 mb-3 view-detail-table">
                          {athleteDetails.galleryData[0] && athleteDetails.galleryData[0].length > 0 ? (
                            athleteDetails.galleryData[0].map((imageItem) => (
                              <div key={imageItem.id} className="col-xl-12 mb-3 p-2">
                                <img
                                  src={imageItem.fileLocation}
                                  alt={imageItem.description || 'club-image'}  // optional: provide a meaningful alt if available
                                  className="p-2"
                                />
                              </div>
                            ))
                          ) : (
                            <div className="col-xl-12 mb-3 p-2">
                              <img src={noimage} alt="noimage" />
                            </div>
                          )}
                        </div>

                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">First Name:</span>
                          {athleteDetails.userData.firstName}
                        </p>
                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Last Name:</span>
                          {athleteDetails.userData.lastName}
                        </p>
                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Age:</span>
                          {athleteDetails.athleteData.age}
                        </p>
                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Gender:</span>
                          {athleteDetails.athleteData.gender}
                        </p>
                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Residential City:</span>
                          {athleteDetails?.citiesData?.name}
                        </p>
                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Residential State:</span>
                          {athleteDetails?.stateData?.name}
                        </p>
                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">School:</span>
                          {athleteDetails.athleteData.school}
                        </p>
                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Grade:</span>
                          {athleteDetails.athleteData.grade}
                        </p>
                      </div>

                      <div className="col-xl-12 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Bio:</span> {stripHtmlTags(athleteDetails.athleteData.bio)}
                        </p>
                      </div>
                      <h3 className='profile-head'>Sport Details</h3>

                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Primary Sport:</span>
                          {athleteDetails?.sportData[0]?.sportName}
                        </p>
                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Current Academy:</span> {stripHtmlTags(athleteDetails.athleteData.currentAcademie)}

                        </p>
                      </div>




                      <div className="col-xl-12 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Athlete Specialty:</span>
                          {athleteDetails.athleteData.athleteSpecialty}
                        </p>
                      </div>


                      <div className="col-xl-12 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Achievements:</span>{" "}
                          {stripHtmlTags(athleteDetails.athleteData.achievements)}
                        </p>
                      </div>
                      <div className="col-xl-12 mb-3 p-2">
                        <span className="view-h">Headshot Images</span>
                      </div>
                      {athleteDetails?.galleryData?.some(imageitem => imageitem?.fileType?.trim().toLowerCase() === "headshot image") ? (
                        <div className="col-xl-6 mb-3 p-2 d-flex justify-content-between align-items-center gap-2 flex-wrap view-image">
                          {athleteDetails?.galleryData?.map((imageitem) => (
                            imageitem?.fileType?.trim().toLowerCase() === "headshot image" && (
                              <img
                                key={imageitem.id}
                                src={imageitem?.fileLocation}
                                alt="Headshot"
                                className="p-2"
                              />
                            )
                          ))}
                        </div>
                      ) : (
                        <div className="col-xl-6 mb-3 p-2 d-flex justify-content-between align-items-center gap-2 flex-wrap view-image">
                          <p className="view-h text-warning">
                            <img src={noimage} alt="noimage" />
                          </p>
                        </div>
                      )}

                      {athleteDetails?.galleryData?.some(videoitem => videoitem?.fileType?.trim().toLowerCase() === "video") ? (
                        <>
                          <div className="col-xl-12 mb-3 p-2">
                            <span className="view-h">Videos</span>
                          </div>
                          <div className="col-xl-12 mb-3 p-2 d-flex justify-content-between align-items-center gap-2 flex-wrap">
                            {athleteDetails?.galleryData?.map((videoitem) => {
                              const fileType = videoitem?.fileType?.trim().toLowerCase();
                              return fileType === "video" ? (
                                <video key={videoitem.id} controls className="p-2" style={{ width: "400px", height: "300px" }}>
                                  <source src={videoitem?.fileLocation} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ) : null;
                            })}
                          </div>
                        </>
                      ) : (
                        <div className="col-xl-12 mb-3 p-2">
                          <p className="view-h text-warning">No Video</p>
                        </div>
                      )}


                      <h3 className='profile-head'>Parent Details</h3>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Parent First Name:</span>
                          {athleteDetails.athleteData.parentFirstName}
                        </p>
                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Parent Last Name:</span>
                          {athleteDetails.athleteData.parentLastName}
                        </p>
                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Parent Contact Number:</span>
                          {athleteDetails.athleteData.parentPhone}
                        </p>
                      </div>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Parent Email:</span>
                          {athleteDetails.userData.email}
                        </p>
                      </div>
                      <h3 className='profile-head'>Profile Status</h3>
                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Subscription Status:</span>
                          {athleteDetails?.subscriptionData
                            ? athleteDetails.subscriptionData.subscribtionStatus
                              ? athleteDetails.subscriptionData.subscribtionStatus
                              : "Not Defined"
                            : "Not Defined"}
                        </p>
                      </div>

                      <div className="col-xl-6 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Approval Status:</span>
                          {athleteDetails.athleteData.isApprove}
                        </p>
                      </div>
                      <div className="col-xl-12 mb-3 view-detail-table">
                        <p className="view-p">
                          <span className="view-h">Publish Status:</span>
                          {athleteDetails.isActive === true ? "Published" : "Unpublished"}
                        </p>
                      </div>



                      {athleteDetails?.subscriptionData && (
                        <>
                          <h3 className='profile-head'>Subscription Details</h3>
                          <div className="col-xl-6 mb-3 view-detail-table">
                            <p className="view-p">
                              <span className="view-h">Subscription Name:</span>
                              {athleteDetails.subscriptionData.subscriptionName || "Not Defined"}
                            </p>
                          </div>

                          <div className="col-xl-6 mb-3 view-detail-table">
                            <p className="view-p">
                              <span className="view-h">Subscription Amount:</span>
                              {athleteDetails.subscriptionData.subscriptionAmount || "Not Defined"}
                            </p>
                          </div>

                          <div className="col-xl-6 mb-3 view-detail-table">
                            <p className="view-p">
                              <span className="view-h">Processing Fee:</span>
                              {athleteDetails.subscriptionData.processingTax || "Not Defined"}
                            </p>
                          </div>

                          <div className="col-xl-6 mb-3 view-detail-table">
                            <p className="view-p">
                              <span className="view-h">Convenience Fee:</span>
                              {athleteDetails.subscriptionData.convenienceTax || "Not Defined"}
                            </p>
                          </div>

                          <div className="col-xl-12 mb-3 view-detail-table">
                            <p className="view-p">
                              <span className="view-h">Service Fee:</span>
                              {athleteDetails.subscriptionData.serviceTax || "Not Defined"}
                            </p>
                          </div>
                        </>
                      )}

                    </div>
                  </form>
                </div>

              </>

            </Offcanvas.Body>
          </>
        ) : (
          ""
        )}
      </Offcanvas>
    </>
  );
});

export default ViewAthleteOffcanvas;
