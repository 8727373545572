import React from 'react'
import Parent from './ParentConsent/Parent'

const ParentConsent = () => {
  return (
    <>
    <Parent/>
      
    </>
  )
}

export default ParentConsent
