import React, { useState } from "react";
/// React router dom

import { Link } from "react-router-dom";
import Logo from "../../../images/logo/logo.png";
import logo1 from "../../../images/logo/fav-blue.png"
export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    console.log("mainwrapper:", mainwrapper);
    if (mainwrapper) {
      console.log("classList:", mainwrapper.classList);
      if (mainwrapper.classList.contains("menu-toggle")) {
        mainwrapper.classList.remove("menu-toggle");
      } else {
        mainwrapper.classList.add("menu-toggle");
      }
    }
  }, 200);
}

 
const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const toggleImage = () => {
    setToggle(!toggle);
  };
  return (
    <div className="nav-header">
    <Link to="/dashboard" className="brand-logo">
      {toggle ? (
        <img src={logo1} alt="anotherlogo" height={35} width={30} className="" />
      ) : (
        <img src={Logo} alt="companylogo" height={80} width={190} className="" />
      )}
    </Link>
    <div
      className="nav-control"
      // onClick={toggleImage}
      onClick={() => {
        setToggle(!toggle);
        // openMenuToggle();
        NavMenuToggle();
      }}
    >
      <div className={`hamburger ${toggle ? "is-active" : ""}`}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
    </div>
  </div>
  );
};

export default NavHader;
