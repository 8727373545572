/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import noimage from '../../images/noprofile/noprofile2.jpg'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()

    .max(15, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()

    .max(15, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),

  profileImg: Yup.string(), // Assuming it's a string for image URL
  age: Yup.number()
    .integer('Age must be an integer')
    .positive('Age must be a positive number'),
  gender: Yup.string()
    .required('Required'),
  city: Yup.string()

    .max(15, 'Too Long!')
    .required('Required'),
  residentialState: Yup.string()
    .required('Required'),

  bio: Yup.string()
    .max(1000, "Bio cannot exceed 1000 characters")
    .required('Required'),

  achievements: Yup.string().max(1000, "Achievements cannot exceed 1000 characters"),
  grade: Yup.string()
    .required('Required'),

  parentFirstName: Yup.string()

    .max(15, 'Too Long!')
    .required('Required'),
  parentLastName: Yup.string()

    .max(15, 'Too Long!')
    .required('Required'),

  parentPhone: Yup.string()
    .required('Required'),
  parentConsent: Yup.boolean()
    .required('Required'),


  sportsId: Yup.string()
    .required('Required'),
  subscriptionId: Yup.string()
    .required('Required'),


});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  profileImg: "",
  age: "",
  gender: "",
  city: "",
  residentialState: "",
  school: "",
  grade: "",
  bio: "",
  achievements: "",
  parentFirstName: "",
  parentLastName: "",
  parentEmail: "",
  parentPhone: "",
  parentConsent: "",
  twitterLink: "",
  instagramLink: "",
  sportsId: "",
  subscriptionId: "",
  athleteSpecialty: "",
  currentAcademie: "",
  subscriptionStatus: "",
  isApprove: "",
  isActive: "",


};
const EditAthleteOffcanvas = forwardRef((props, ref) => {
  const [editAthlete, setEditAthlete] = useState(false);
  const [sportData, setSportData] = useState(null);
  const [formData, setFormData] = useState(initialValues);
  const [galleryId, setGalleryId] = useState(null);
  const [profile, setProfile] = useState(null);
  const [athleteId, setAthleteId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState('');
  const [specialties, setSpecialties] = useState([]);
  const [headshotImages, setHeadshotImages] = useState([])
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  useImperativeHandle(ref, () => ({
    showEmployeModal(id) {
      console.log('Athlete ID:', id);
      setEditAthlete(true);
      fetchAthleteById(id);
      fetchAge();
      fetchStates();
    }
  }));
  const handleVideoUpload = async (event) => {
    const files = Array.from(event.currentTarget.files).slice(0, 3);
    const newValidVideos = [];
    const newVideoPreviews = [];
    const errors = [];

    for (const file of files) {
      const isValid = await validateVideo(file);
      if (isValid) {
        newValidVideos.push(file);
        newVideoPreviews.push(URL.createObjectURL(file));
      } else {
        toast.error(`${file.name} is either too long or too large.`);
      }
    }

    setSelectedVideos((prevVideos) => [...prevVideos, ...newValidVideos]);
    setVideoPreviews((prevPreviews) => [...prevPreviews, ...newVideoPreviews]);
    setValidationErrors((prevErrors) => [...prevErrors, ...errors]);
  };

  const validateVideo = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        const fileSize = file.size / 1024; // Convert to KB

        if (duration <= 30 && fileSize <= 250) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      video.src = URL.createObjectURL(file);
    });
  };

  useEffect(() => {
    setCities([]);
    if (selectedStateId) {
      fetchCitiesByState(selectedStateId);
    }
  }, [selectedStateId]);
  useEffect(() => {
    if (formData.sportsId) {
      fetchSpecialtiesBysportsId(formData.sportsId);
    }
  }, [formData.sportsId]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios.get(`${process.env.REACT_APP_API_URL}/getallsports`, {
      headers: {
        'authorization': `${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setSportData(response.data);
      })
      .catch(error => {
        console.error('Error fetching sportData data:', error);
      });
  }, []);
  const fetchAge = async () => {
    try {
      const token = localStorage.getItem('token');
      const id = localStorage.getItem('id');
      console.log(id);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getsettings/1`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });

      const fromAge = parseInt(response.data.data.fromAge);
      const toAge = parseInt(response.data.data.toAge);

      const options = [];
      for (let i = fromAge; i <= toAge; i++) {
        options.push(i.toString());
      }
      setAgeOptions(options);
    } catch (error) {
      console.error('Error fetching age:', error);
    }
  };


  const handleApprove = async (videoId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/galleryisapprove/${videoId}`,
        { isApproved: true },
        {
          headers: {
            'authorization': `${token}`,
          }
        }
      );
      toast.success("Video approved successfully!");
      fetchAthleteById(athleteId);
    } catch (error) {
      console.error("Error approving video:", error);
      toast.error("Failed to approve video.");
    }
  };


  const handleReject = async (videoId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(`${process.env.REACT_APP_API_URL}/delete-file/${videoId}`, {}, {
        headers: {
          'authorization': `${token}`,
        }
      });
      toast.success("Video rejected successfully!");
      fetchAthleteById(athleteId);
    } catch (error) {
      console.error("Error rejecting video:", error);
      toast.error("Failed to reject video.");
    }
  };
  const [selectedFiles, setSelectedFiles] = useState({});

  const handleFileSelection = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const fileSize = file.size / 1024;
      if (fileSize > 250) {
        toast.error("File size exceeds 250 KB");
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const { width, height } = img;
          if (width >= 290 && width <= 300 && height >= 290 && height <= 300) {
            const fileURL = URL.createObjectURL(file);
            const updatedFiles = { ...selectedFiles, [index]: { file, fileURL } };
            setSelectedFiles(updatedFiles);
          } else {
            toast.error("Image dimensions must be between 290x290 and 300x300 pixels");
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAdditionalFileChange = async (index) => {
    const selectedFile = selectedFiles[index];
    if (selectedFile) {
      const { file, fileURL } = selectedFile;
      try {
        const token = localStorage.getItem("token");
        const fileFormData = new FormData();
        fileFormData.append('fileLocation', file);
        fileFormData.append('userId', athleteId);
        fileFormData.append('description', `Headshot Image`);
        fileFormData.append('isActive', "true");
        fileFormData.append('fileType', `Headshot Image`);
        fileFormData.append('isApproved', "true");

        await axios.post(`${process.env.REACT_APP_API_URL}/upload-singlefile`, fileFormData, {
          headers: {
            'authorization': `${token}`,
            'Content-Type': 'multipart/form-data',
          }
        });

        console.log("File uploaded successfully!");
        toast.success("File uploaded successfully!");

        const updatedImages = [...headshotImages];
        updatedImages[index] = { fileLocation: fileURL };
        setHeadshotImages(updatedImages);

        const updatedFiles = { ...selectedFiles };
        delete updatedFiles[index];
        setSelectedFiles(updatedFiles);

      } catch (error) {
        console.error("Error occurred during file upload:", error);
        toast.error("An error occurred during file upload.");
      }
    }
  };

  const handleSave = (index) => {
    handleAdditionalFileChange(index);
  };

  const handleDeleteAdditionalImage = async (index) => {
    try {
      const imageToDelete = headshotImages[index];

      if (!imageToDelete || !imageToDelete.id) {
        const updatedImages = [...headshotImages];
        updatedImages.splice(index, 1);
        setHeadshotImages(updatedImages);
        toast.success("Image deleted successfully!");
        return;
      }
      const token = localStorage.getItem("token");
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/delete-file/${imageToDelete.id}`, {}, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'multipart/form-data',
        }
      });

      if (response.status === 200) {
        const updatedImages = [...headshotImages];
        updatedImages.splice(index, 1);
        setHeadshotImages(updatedImages);
        toast.success("Image deleted successfully!");
      } else {
        console.error('Failed to delete image:', response);
        toast.error("Failed to delete image.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized access. Please log in again.");
      } else {
        console.error("Error occurred during image deletion:", error);
        toast.error("An error occurred during image deletion.");
      }
    }
  };



  const handleInputChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  const handleEditorChange = (editorName, data) => {
    setFormData(prevState => ({
      ...prevState,
      [editorName]: data
    }));
  };

  const handleStateChange = (selectedOption) => {
    setFormData({
      ...formData,
      residentialState: selectedOption.value,
      city: "",
    });
    fetchCitiesByState(selectedOption.value);
  };

  const handleCityChange = (selectedOption) => {
    setFormData({
      ...formData,
      city: selectedOption.value,
    });
  };

  useEffect(() => {
    if (formData?.residentialState) {
      fetchCitiesByState(formData.residentialState);
    }
  }, [formData?.residentialState]);

  const fetchCitiesByState = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallcities?stateId=${id}`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'authorization': `${token}`,
        }
      });
      if (response.data && Array.isArray(response.data.citiesData)) {
        setCities(response.data.citiesData.map(city => ({ value: city.id, label: city.name })));
      } else {
        console.error('Error: citiesData is not an array or is undefined');
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fetchStates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallstates`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'authorization': `${token}`,
        }
      });
      setStateOptions(response.data.data.map(state => ({ value: state.id, label: state.name }))); // Map the states array for react-select
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);



  const fetchAthleteById = async (id) => {
    setProfileImage(null);
    setSelectedVideos([]);
    setHeadshotImages([]);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getathlete/${id}`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      const responseData = response.data.data;
      const fetchAthleteId = responseData?.userData?.id;
      setAthleteId(fetchAthleteId);
      const fetchedGalleryId = responseData?.galleryData?.[0]?.id;
      setGalleryId(fetchedGalleryId);

      setProfile(responseData?.galleryData?.[0]?.fileLocation);

      const galleryData = responseData.galleryData;
      const headshot = galleryData.filter((item) => item.fileType.startsWith("Headshot Image"));
      const sortedHeadshot = headshot.sort((a, b) => {
        const getFileNumber = (fileType) => parseInt(fileType.replace('Headshot Image ', ''), 10);
        return getFileNumber(a.fileType) - getFileNumber(b.fileType);
      });
      const videosData = galleryData.filter((item) => item.fileType === "Video");
      setVideos(videosData);

      if (sortedHeadshot.length > 0) {
        setHeadshotImages(sortedHeadshot);
        setAdditionalImages(sortedHeadshot);
      }

      if (videosData.length > 0) {
        setVideo(videosData[0].fileLocation);
      }
      const mappedData = {
        id: responseData.userData.id || "",
        firstName: responseData.userData.firstName || "",
        lastName: responseData.userData.lastName || "",
        email: responseData.userData.email || "",
        age: responseData.athleteData.age || "",
        gender: responseData.athleteData.gender || "",
        city: responseData?.citiesData?.id || "",
        residentialState: responseData?.stateData?.id || "",
        school: responseData.athleteData.school || "",
        bio: responseData.athleteData.bio || "",
        grade: responseData.athleteData.grade || "",
        achievements: responseData.athleteData.achievements || "",
        parentFirstName: responseData.athleteData.parentFirstName || "",
        parentLastName: responseData.athleteData.parentLastName || "",
        parentEmail: responseData.athleteData.email || "",
        parentPhone: responseData.athleteData.parentPhone || "",
        parentConsent: responseData.athleteData.parentConsent || "",
        twitterLink: responseData.athleteData.twitterLink || "",
        instagramLink: responseData.athleteData.instagramLink || "",
        sportName: responseData.sportData.sportName || "",
        sportsId: responseData.athleteData.sportsId || "",
        subscriptionId: responseData.athleteData.subscriptionId || "",
        athleteSpecialty: responseData?.specialityData.specialityTitle || "",
        currentAcademie: responseData.athleteData.currentAcademie || "",
        subscriptionStatus: responseData?.subscriptionData?.subscribtionStatus || "",
        isApprove: responseData.athleteData.isApprove || "",
        isActive: responseData.athleteData.isActive || "",
        galleryId: responseData.athleteData.galleryId || "",
      };

      setFormData(mappedData); // Update form data
    } catch (error) {
      console.error("Error fetching athlete data:", error);
      toast.error("Failed to fetch athlete data!");
    }
  };
  const fetchSpecialtiesBysportsId = async (sportsId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getspecialitybysport/${sportsId}`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'authorization': `${token}`,
        }
      });

      if (response.data && Array.isArray(response.data.data.specialities)) {
        setSpecialties(response.data.data.specialities.map(specialty => ({
          value: specialty.id,
          label: specialty.specialityTitle
        })));
      } else {
        console.error('Error: specialties is not an array or is undefined');
      }
    } catch (error) {
      console.error('Error fetching specialties:', error);
    }
  };


  const handleSportChange = (e) => {
    const selectedsportsId = e.target.value;
    setFormData({
      ...formData,
      sportsId: selectedsportsId,
      athleteSpecialty: '', // Reset specialty when sport changes
    });
    fetchSpecialtiesBysportsId(selectedsportsId);
  };
  // Update athlete
  const handleSubmit = async (e, values) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      // Update athlete data
      if (galleryId) {
        values.galleryId = galleryId;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/updateathlete`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `${token}`,
          },
        }
      );

      if (response.data.status === 200 && response.data) {
        console.log("Response:", response.data);

        if (selectedFile && !galleryId) {


          console.log("Profile Uploading")
          const fileFormData = new FormData();
          fileFormData.append('userId', athleteId);
          fileFormData.append('fileLocation', selectedFile, selectedFile.name);
          fileFormData.append('description', "New Profile Image");
          fileFormData.append('isActive', "true");
          fileFormData.append('fileType', "Profile Image");
          fileFormData.append('isApproved', "true");
          console.log(fileFormData);

          await axios.post(`${process.env.REACT_APP_API_URL}/upload-singlefile`, fileFormData, {
            headers: {
              'authorization': `${token}`,
              'Content-Type': 'multipart/form-data',
            }
          });
          console.log("File uploaded successfully!");
          toast.success("File uploaded successfully!");
        }

        // Update existing profile picture
        if (selectedFile && galleryId) {
          const fileFormData = new FormData();
          fileFormData.append('id', galleryId);
          fileFormData.append('userId', response.data);
          fileFormData.append('fileLocation', selectedFile, selectedFile.name);
          fileFormData.append('description', "Profile Image");
          fileFormData.append('isActive', "true");
          fileFormData.append('fileType', "Profile Image");
          fileFormData.append('isApproved', "true");

          await axios.post(`${process.env.REACT_APP_API_URL}/update-file`, fileFormData, {
            headers: {
              'authorization': `${token}`,
              'Content-Type': 'multipart/form-data',
            }
          });
          console.log("File updated successfully!");
        }
        console.log("videoFile", values, selectedVideos);

        if (selectedVideos && selectedVideos.length > 0) {
          const videoFormData = new FormData();
          selectedVideos.forEach((file) => {
            videoFormData.append('fileLocation', file);
          });
          videoFormData.append('userId', athleteId);
          videoFormData.append('description', "Video Files");
          videoFormData.append('isActive', "true");
          videoFormData.append('fileType', "Video");
          videoFormData.append('isApproved', "true");

          try {
            await axios.post(`${process.env.REACT_APP_API_URL}/upload-multiplefiles`, videoFormData, {
              headers: {
                'authorization': `${token}`,
                'Content-Type': 'multipart/form-data',
              }
            });
            console.log("Multiple video files uploaded successfully!");
          } catch (error) {
            if (error.response && error.response.status === 413) {
              console.error("File size too large.");
              toast.error("File Size Too Large");
            } else {
              console.error("An error occurred during file upload.");
              toast.error("An error occurred during file upload.");
            }
          }
        }

        toast.success("Athlete data updated successfully!");
        props.onAthleteUpdated();
        setEditAthlete(false);
      }
    } catch (error) {
      console.error("Error updating athlete data:", error);
      toast.error(error?.response?.data?.message);
    }
  };





  return (
    <>
      <Offcanvas
        show={editAthlete}
        onHide={() => setEditAthlete(false)}
        className="offcanvas-end customeoff"
        placement="end"
      >
        <div className="offcanvas-header profile-head-h">
          <h3 className="modal-title text-white" id="#gridSystemModal">{props.Title}</h3>

          <button
            type="button"
            className="btn-close"
            onClick={() => setEditAthlete(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">

            <Formik
              initialValues={formData || initialValues} // Use formData if available, otherwise use initialValues
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                props.handleSubmit(values);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, values, errors, setFieldValue, setFieldTouched }) => (

                <Form onSubmit={(e) => {
                  e.preventDefault(); // Prevent default form submission behavior
                  handleSubmit(e, values);


                }}>
                  <div className="row">
                    <label>Profile Picture</label>
                    <div className="dz-default dlab-message upload-img mb-3">
                      <div className="col-xl-6 mb-3">
                        {profile && (
                          <img
                            src={profile}
                            alt="Selected File"

                          />
                        )}
                      </div>
                      <Field
                        type="file"
                        name="profileImg"
                        className="form-control"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          if (file) {
                            const fileSize = file.size / 1024; // size in KB
                            if (fileSize > 200) {
                              toast.error("File size exceeds 200 KB");
                              return;
                            }

                            const reader = new FileReader();
                            reader.onload = () => {
                              const img = new Image();
                              img.src = reader.result;
                              img.onload = () => {
                                const { width, height } = img;
                                if (width >= 290 && width <= 300 && height >= 290 && height <= 300) {
                                  setProfile(reader.result);
                                  setSelectedFile(file);
                                } else {
                                  toast.error("Image dimensions must be between 290x290 and 300x300 pixels");
                                }
                              };
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                      <p className="text-danger">Height & Width should be between 290 - 300  </p>
                      <p className="text-danger">only GIF, JPG, JPEG, PNG, or SVG images up to 200 KB. </p>
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="firstName" className="form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                      />
                      <ErrorMessage name="firstName" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="lastName" className="form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                      />
                      <ErrorMessage name="lastName" component="div" className="text-danger" />
                    </div>


                    <div className="col-xl-6 mb-3">
                      <label htmlFor="age" className="form-label">
                        Age <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        name="age"
                        className="form-control"
                        id="age"
                      >
                        <option value="">Select Age</option>
                        {ageOptions.length > 0 ? (
                          ageOptions.map((ageValue, index) => (
                            <option key={index} value={ageValue}>{ageValue}</option>
                          ))
                        ) : (
                          <option value="" disabled>No Options</option>
                        )}
                      </Field>


                      <ErrorMessage name="age" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="gender" className="form-label">
                        Gender <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        className="form-control"
                        id="gender"
                        value={values.gender}
                        name="gender"
                      >
                        <option value="">Please select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </Field>
                      <ErrorMessage name="gender" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="residentialState" className="form-label">
                        Residential State <span className="text-danger">*</span>
                      </label>
                      <Select
                        id="residentialState"
                        name="residentialState"
                        options={stateOptions}
                        classNamePrefix="react-select"
                        value={stateOptions.find((option) => option.value === values.residentialState) || null}
                        onChange={handleStateChange}
                      />
                      <ErrorMessage name="residentialState" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="city" className="form-label">
                        Residential City <span className="text-danger">*</span>
                      </label>
                      <Select
                        id="city"
                        name="city"
                        options={cities}
                        value={cities.find((option) => option.value === values.city) || null}
                        onChange={handleCityChange}
                      />
                      <ErrorMessage name="city" component="div" className="text-danger" />
                    </div>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="school" className="form-label">
                        School
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="school"
                        name="school"
                      />
                      <ErrorMessage name="school" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="grade" className="form-label">
                        Grade
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="grade"
                        name="grade"
                      />
                      <ErrorMessage name="grade" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="twitterLink" className="form-label">
                        Twitter Link
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="twitterLink"
                        name="twitterLink"
                        value={values.twitterLink || ""}
                      />
                      <ErrorMessage name="twitterLink" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="instagramLink" className="form-label">
                        Instagram Profile Link
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="instagramLink"
                        name="instagramLink"
                      />
                      <ErrorMessage name="instagramLink" component="div" className="text-danger" />
                    </div>


                    <div className="col-xl-12 mb-3">
                      <label htmlFor="bio" className="form-label">
                        Bio
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={formData?.bio} // Use data prop instead of value
                        onChange={(event, editor) => {
                          setFieldTouched('bio', true);

                          handleEditorChange("bio", editor.getData()); // Call handleEditorChange with editorName
                        }}
                      />
                      <ErrorMessage name="bio" component="div" className="text-danger" />
                    </div>

                    <h3 className='profile-head'>Parent Details</h3>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="parentFirstName" className="form-label">
                        Parent First Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="parentFirstName"
                        name="parentFirstName"
                      />
                      <ErrorMessage name="parentFirstName" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="parentLastName" className="form-label">
                        Parent Last Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="parentLastName"
                        name="parentLastName"
                      />
                      <ErrorMessage name="parentLastName" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="email" className="form-label">
                        Parent Email <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                      />
                      <ErrorMessage name="email" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="parentPhone" className="form-label">
                        Parent Phone Number <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="parentPhone"
                        name="parentPhone"
                      />
                      <ErrorMessage name="parentPhone" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-6 mb-3 ">
                      <label htmlFor="parentConsent" className="form-label p-2">
                        Parent Consent <span className="text-danger">*</span>
                      </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="parentConsent"
                        name="parentConsent"
                        checked={formData?.parentConsent}
                        onChange={handleInputChange}
                      />
                      <div className="text-danger">
                        {formData?.parentConsent ? null : ""}
                      </div>
                    </div>


                    <h3 className='profile-head'>Sport Details</h3>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="sportsId" className="form-label">
                        Primary Sport <span className="text-danger">*</span>
                      </label>
                      <Field as="select" className="form-control" id="sportsId" name="sportsId" onChange={handleSportChange}>
                        <option value="">Select a sport</option>
                        {sportData && sportData?.data?.map(sport => (
                          <option key={sport.id} value={sport.id}>{sport.sportName}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="sportsId" component="div" className="text-danger" />
                    </div>


                    <div className="col-xl-6 mb-3">
                      <label htmlFor="athleteSpecialty" className="form-label">
                        Specialty
                      </label>
                      <Field as="select" className="form-control" id="athleteSpecialty" name="athleteSpecialty">
                        {/* <option value="">{values.athleteSpecialty}</option> */}
                        {specialties && specialties.map(specialty => (
                          <option key={specialty.id} value={specialty.value}>{specialty.label}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="athleteSpecialty" component="div" className="text-danger" />
                    </div>
                    <div className="col-xl-12 mb-3">
                      <label htmlFor="currentAcademie" className="form-label">
                        Current Academy
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={formData?.currentAcademie} // Use data prop instead of value
                        onChange={(event, editor) => {
                          setFieldTouched('currentAcademie', true);
                          handleEditorChange("currentAcademie", editor?.getData()); // Call handleEditorChange with editorName
                        }}
                      />

                      <ErrorMessage name="currentAcademie" component="div" className="text-danger" />
                    </div>



                    <div className="col-xl-12p0][] mb-3">
                      <label htmlFor="achievements" className="form-label">
                        Achievements
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={formData?.achievements} // Use data prop instead of value
                        onChange={(event, editor) => {
                          setFieldTouched('achievements', true);
                          handleEditorChange("achievements", editor?.getData()); // Call handleEditorChange with editorName
                        }}
                      />
                      <ErrorMessage name="achievements" component="div" className="text-danger" />
                    </div>
                    <h3 className='profile-head'>Gallery Details</h3>

                    <div className="pl-25">
                      <label htmlFor="additionalImage" className="form-label">Gallery Image</label>
                      <p className="text-danger">
                        Image dimensions must be between 290x290 and 300x300 pixels
                      </p>
                      <p className="text-danger">
                        File Size under 250KB
                      </p>
                      <div className="row mt-3">
                        {headshotImages && headshotImages.length > 0 && headshotImages.map((image, index) => (
                          <div key={index} className="col-xl-4 mb-3 p-2 d-grid">
                            <img src={image.fileLocation} alt={`Headshot Image ${index + 1}`} style={{ width: "200px", height: "225px" }} />
                            <button
                              type="button"
                              className="btn btn-danger btn-md mt-2"
                              onClick={() => handleDeleteAdditionalImage(index)}
                            >
                              Delete
                            </button>
                          </div>
                        ))}
                        {[...Array(Math.max(0, 3 - headshotImages.length))].map((_, index) => (
                          <div key={index} className="col-xl-4 mb-3 p-2 d-grid">
                            <div className="d-flex align-items-center">
                              <input
                                type="file"
                                className="file-upload-input visually-hidden"
                                id={`additionalImage${headshotImages.length + index}`}
                                name={`additionalImage${headshotImages.length + index}`}
                                onChange={(e) => handleFileSelection(e, headshotImages.length + index)}
                              />
                              <img
                                src={selectedFiles[headshotImages.length + index]?.fileURL || noimage}
                                alt="No Image Available"
                                style={{ width: "200px", height: "225px" }}
                              />
                              <label htmlFor={`additionalImage${headshotImages.length + index}`} className="btn btn-primary btn-sm mt-2" style={{ height: 'max-content' }}>
                                +
                              </label>
                            </div>
                            <button
                              width="80"
                              type="button"
                              className="btn btn-primary btn-md mt-2"
                              onClick={() => handleSave(headshotImages.length + index)}
                            >
                              Save
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-xl-12 mb-3 pl-25">
                      <label htmlFor="videoFile" className="form-label">Video</label>
                      
                      <div className="file-upload-wrapper pl-25">
                        <input
                          type="file"
                          className="file-upload-input"
                          id="videoFile"
                          name="videoFile"
                          multiple
                          accept="video/*" // Accept only video files
                          onChange={handleVideoUpload}
                        />
                        <label htmlFor="videoFile" className="file-upload-label">+</label>
                      </div>
                      <p className="text-danger">
                        Video Duration should be maximum 30 seconds
                      </p>
                      <p className="text-danger">
                        Video Size under 250KB
                      </p>
                      {validationErrors.length > 0 && (
                        <div className="validation-errors">
                          {validationErrors.map((error, index) => (
                            <p key={index} className="text-danger">{error}</p>
                          ))}
                        </div>
                      )}
                      <div 
                      className="col-xl-12 mb-3 p-2 d-flex">
                        {videoPreviews.length > 0 && videoPreviews.map((video, index) => (
                          <div key={index} className="video-container p-2">
                            <video controls width="300" height="300">
                              <source src={video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        ))}
                        {videos.length > 0 && videos.map((video) => (
                          <div key={video.id} className="video-container p-2">
                            <video controls width="300" height="300">
                              <source src={video.fileLocation} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                            {!video.isApproved && (
                              <div className="video-actions d-flex">
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-md mt-2 me-1"
                                    onClick={() => handleApprove(video.id)}
                                  >
                                    Approve
                                  </button>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-md mt-2"
                                    onClick={() => handleReject(video.id)}
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>


                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary me-1" disabled={isSubmitting}>
                      Save
                    </button>
                    <Link
                      to="#"
                      onClick={() => setEditAthlete(false)}
                      className="btn btn-secondary"
                    >
                      Cancel
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Offcanvas>
      <ToastContainer />
    </>
  );

});

export default EditAthleteOffcanvas;
