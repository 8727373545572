import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import axios from 'axios';
import parse from 'html-react-parser';
const stripHtmlTags = (html) => {
    // Parse HTML content
    const parsedHtml = parse(html);

    // Apply styles to elements
    const styledHtml = React.Children.map(parsedHtml, (child) => {
        // Check if child is an element
        if (React.isValidElement(child)) {
            // Extract style attribute
            const style = child.props.style;

            // If style exists, apply it
            if (style) {
                // Convert style object to string
                const styleString = Object.entries(style)
                    .map(([key, value]) => `${key}:${value}`)
                    .join(';');

                // Create a new element with applied styles
                return React.cloneElement(child, { style: styleString });
            }
        }

        // If no styles, return the original child
        return child;
    });

    return styledHtml;
};
const ViewSportOffcanvas = forwardRef((props, ref) => {
    const [addEmploye, setAddEmploye] = useState(false);
    const [sportData, setSportData] = useState(null);
    const [sportId, setSportId] = useState(null);
    useImperativeHandle(ref, () => ({
        showEmployeModal(id) {
            setSportId(id);
            setAddEmploye(true);
        },
    }));

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Retrieve token from localStorage
                const token = localStorage.getItem('token');

                // Make GET request to fetch sport data by ID
                const response = await axios.get(`https://api.engageathlete.com/api/v1/getsubscription/${sportId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'authorization': token
                    }
                });

                // Set the fetched data to state
                setSportData(response.data.data);
                console.log("Data", sportData.data);
            } catch (error) {
                console.error('Error fetching sport data:', error);
            }
        };

        if (sportId) {
            fetchData();
        }
    }, [sportId]);
    const stripHtmlTags = (html) => {
      
        const parsedHtml = parse(html);

       
        const styledHtml = React.Children.map(parsedHtml, (child) => {
          
            if (React.isValidElement(child)) {
              
                const style = child.props.style;

               
                if (style) {
                  
                    const styleString = Object.entries(style)
                        .map(([key, value]) => `${key}:${value}`)
                        .join(';');

                  
                    return React.cloneElement(child, { style: styleString });
                }
            }

          
            return child;
        });

        return styledHtml;
    };
    return (
        <>
            <Offcanvas
                show={addEmploye}
                onHide={() => setAddEmploye(false)}
                className="offcanvas-end customeoff"
                placement="end"
            >
                <div className="offcanvas-header profile-head-h">
                    <h5 className="modal-title text-white" id="#gridSystemModal">
                        {props.Title}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => setAddEmploye(false)}
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <form>
                            {sportData && (
                                <div className="row d-flex justify-content-around">

                                    <div className="col-xl-6 mb-3 view-detail-table">
                                        <p className="view-p"><span className="view-h">Name:</span> {sportData.subscriptionName}</p>

                                    </div>
                                    
                                    <div className="col-xl-6 mb-3 view-detail-table">
                                    <p className="view-p"><span className="view-h">Duration:</span> {sportData.duration}</p>
                                    </div>
                                    <div className="col-xl-6 mb-3 view-detail-table">
                                    <p className="view-p"><span className="view-h">Profile:</span> {sportData?.role?.roleName}</p>
                                    </div>
                                    <div className="col-xl-6 mb-3 view-detail-table">
                                    <p className="view-p"><span className="view-h">Sport:</span> {sportData?.sport?.sportName}</p>
                                    </div>
                                    <div className="col-xl-6 mb-3 view-detail-table">
                                    <p className="view-p"><span className="view-h">Subscription Limit:</span> {sportData.subscriptionLimit}</p>
                                    </div>
                                    <div className="col-xl-6 mb-3 view-detail-table">
                                    <p className="view-p"><span className="view-h">Subscription Amount:</span> {sportData.subscriptionAmount}</p>
                                    </div>
                                    <div className="col-xl-6 mb-3 view-detail-table">
                                    <p className="view-p"><span className="view-h">Processing Fee:</span> {sportData.processingTax}</p>
                                    </div>
                                    <div className="col-xl-6 mb-3 view-detail-table">
                                    <p className="view-p"><span className="view-h">Convenience Fee:</span> {sportData.convenienceTax}</p>
                                    </div>
                                    <div className="col-xl-6 mb-3 view-detail-table">
                                    <p className="view-p"><span className="view-h">Service Fee:</span> {sportData.serviceTax}</p>
                                    </div>

                                    <div className="col-xl-6 mb-3 view-detail-table">
                                    <p className="view-p"><span className="view-h">Status:</span> {sportData.subscribtionStatus}</p>
                                        
                                    </div>
                                    <div className="col-xl-12 mb-3 view-detail-table">
                                        <p className="view-p">
                                            <span className="view-h">Description:</span>
                                            {stripHtmlTags(sportData.description)}
                                        </p>
                                    </div>

                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </Offcanvas>
        </>
    );
});

export default ViewSportOffcanvas;
